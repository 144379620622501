import React, { useContext, useState, useRef } from 'react'
import axios from "axios";
import { APIURL, TZ } from "../../Global";
//import { useHistory } from "react-router-dom";
import './hospprofile.css'
import { Button, Modal } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import { useNavigate } from "react-router-dom";
import { LoginStatusContext } from "../contexts/LoginStatusContext";
import { RegionDropdown } from "react-country-region-selector";
function LocationUpdate(props) {
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginStatusContext);
  const [hospitalDatas, setHospitalDatas] = useState(JSON.parse(props.loc))
  const navigate = useNavigate();
  // const [location, setLocation] = useState(loc)
  // const[street,setStreet]=useState(strt)
  const [modalShow, setModalShow] = useState(false);
  const alert = useAlert()



  // const history =useHistory();
  const address = useRef(null);
  const [error, setError] = useState({
    location: "",
  });



  const validate = () => {

    let input = hospitalDatas;
    let errors = {};
    let isValid = true;

    if (hospitalDatas.country.trim() === "" || hospitalDatas.country.trim() === null) {
      isValid = false;
      errors["country"] = "Enter Country";

    }
    if (
      typeof hospitalDatas.state === "undefined" ||
      hospitalDatas.state.trim() === "" ||
      hospitalDatas.state.trim() === null
    ) {
      isValid = false;
      errors["state"] = "Enter Region";

    }

    if (
      typeof input["location"] === "undefined" ||
      input["location"].trim() === "" ||
      input["location"].trim() === null
    ) {
      isValid = false;
      errors["location"] = "Enter Location";

    }

    if (
      typeof input["street"] === "undefined" ||
      input["street"].trim() === "" ||
      input["street"].trim() === null
    ) {
      isValid = false;
      errors["street"] = "Enter Street address";

    }

    if (
      typeof input["pincode"] === "undefined" ||
      input["pincode"].trim() === "" ||
      input["pincode"].trim() === null
    ) {
      isValid = false;
      errors["pincode"] = "Enter Zipcode";

    }

    setError(currentstate => ({
      ...currentstate, ...errors
    }))

    return isValid;
  }
  const callLogout = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/account/logout/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
        } else {
        }
      })
      .catch((err) => { });

    navigate("/login");
  };
  const handleLogout = () => {
    callLogout();
    setIsLoggedIn(false);

    sessionStorage.removeItem("role");
    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("usertoken");
    sessionStorage.removeItem("usertype");
    sessionStorage.removeItem("logindatas");
  };
  const handleContinue = async () => {

    if (validate()) {
      submitAddress()
    }
  }

  const submitAddress = (e) => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const data = {

      state: hospitalDatas.state,
      location: hospitalDatas.location,
      street: hospitalDatas.street,
      pincode: hospitalDatas.pincode,
    }

    axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/`, data, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          alert.success("Updated Successfully")
          handleLogout();


        } else {
          alert.error(res.data.message || 'Error occured');

        }

      })
      .catch(err => {
        alert.error('Error')

      })

  }

  const ConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div><div className="alert-border"><span className="alert-icon">!</span></div></div>
          <label style={{ margin: '20px auto 0 auto', color: '#4e4e4e' }}> You will be Logged Out after Updating Hospital Location. Are you sure to Continue? </label>
          <div className="form-group" style={{ display: 'flex', margin: 'auto' }}>
            <button type="button" className='swirl-butns' style={{ margin: '10px 4px 0px 0px', backgroundColor: '#dc3545' }} onClick={props.onHide}> Cancel </button>
            <button type="submit" className='swirl-butns' style={{ margin: '10px 0px 0px 4px', backgroundColor: '#2b69a3' }} onClick={props.onCall}>Confirm </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }


  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    if (key === "name") {
      setError((currentstate) => ({
        ...currentstate,
        "name": "",
      }));
    }
    if (key === "password") {
      setError((currentstate) => ({
        ...currentstate,
        "password": "",
      }));
    }
    if (key === "street") {
      setError((currentstate) => ({
        ...currentstate,
        "street": "",
      }));
    }
    if (key === "location") {
      setError((currentstate) => ({
        ...currentstate,
        "location": "",
      }));
    }

    if (key === "pincode") {
      setError((currentstate) => ({
        ...currentstate,
        "pincode": "",
      }));
    }
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
  };


  const selectRegion = (val) => {
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      state: val,
    }));
    setError((currentstate) => ({
      ...currentstate,
      "state": "",
    }));
  };

  return (
    <div style={{ margin: "auto" }}>
      <div style={{ margin: "auto" }} className="item item-left-row" >
        <label style={{ textAlign: "left" }} className="">* Street Name: </label>{" "}
        <input

          className="form-control "
          type="text"
          name="street"
          placeholder=""
          value={hospitalDatas.street}
          onChange={handleChange}
          maxLength="200"

        />
        {error.street ? (
          <div className="error-validation-msg">{error.street}</div>
        ) : null}
      </div><br />
      <div style={{ margin: "auto" }} className="item item-left-row">


        <label style={{ textAlign: "left" }} className="">* City: </label>{" "}
        <input

          className="form-control"
          type="text"
          name="location"
          placeholder=""
          value={hospitalDatas.location}
          onChange={handleChange}
          maxLength="200"

        />
        {error.location ? (
          <div className="error-validation-msg">
            {error.location}
          </div>
        ) : null}
      </div>
      <br />
      <div style={{ margin: "auto" }} className="region-selector item item-left-row">
        <label style={{ textAlign: "left" }} className=""> * Region: </label>

        <RegionDropdown

          country={hospitalDatas.country}
          value={hospitalDatas.state}
          name="state"
          onChange={(val) => selectRegion(val)}
        // disabled={true}
        />
        {error.state ? (
          <div className="error-validation-msg">{error.state}</div>
        ) : null}

      </div>
      <br />

      <div style={{ margin: "auto" }} className="country-selector item item-left-row">
        <label style={{ textAlign: "left" }} className="">Country: </label>
        <input

          className="form-control disabled-input"
          type="text"
          name="country"
          placeholder=""
          value={hospitalDatas.country}
          onChange={handleChange}
          maxLength="150"
          disabled={true}
        />

        {error.country ? (
          <div className="error-validation-msg">
            {error.country}
          </div>
        ) : null}
      </div>

      <br />

      <div style={{ margin: "auto" }} className="item item-left-row">
        <label style={{ textAlign: "left" }} className="">* Zipcode: </label>{" "}
        <input

          className="form-control "
          type="text"
          name="pincode"
          placeholder=""
          value={hospitalDatas.pincode}
          onChange={handleChange}
          maxLength="50"

        />
        {error.pincode ? (
          <div className="error-validation-msg">
            {error.pincode}
          </div>
        ) : null}
      </div>

      <br />
      <div className="form-button">
        <button onClick={() => setModalShow(true)} className="btn btn-primary btn-col" >Update address</button>
      </div>

      {
        modalShow ?
          <ConfirmPopUp
            show={modalShow}
            onCall={() => { handleContinue(); setModalShow(false) }}
            onHide={() => setModalShow(false)} /> : null
      }


    </div>
  )
}

export default LocationUpdate
