

import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate, Navigate, NavLink } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import request from "../../assets/images/icons/request1.png";

import medicine from "../../assets/images/icons/medicine.png";
import 'font-awesome/css/font-awesome.min.css';

function PharmacyDashboard() {
  const [features,setFeatures]=useState(JSON.parse(sessionStorage.getItem("features")))
  const [loginDatas] = useContext(LoginContext);


  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <>
    <div>
      {/* <HospDocNavbar/> */}
    </div>
    <div >

      <div className="home">
        {/* <h1>Doctor Dashboard</h1> */}
        <h2 className="title-of-page"> {login_datas.name} </h2>
        {/* <h4 className="subtitle-of-page">{login_datas.location}</h4> */}

        <div className="home-container">
          {/*  */}

          


           {/* <div className="home-tiles" 
           onClick={() => navigate("/procedure-request")}
          >
              <img src={procedure} className="home-icon" width="65px" />
            <h5 className="each-title">Procedure Fulfillment</h5> 

          </div>  */}
          
       
          <div className="home-tiles" 
           onClick={() => navigate("/medicine")}
          >
            <img src={medicine} className="home-icon" width="100px" />
            <h5 className="each-title">Medicines</h5> 

          </div>
          {/* <div className="home-tiles">
              <Link to="/medlist" style={{ textDecoration: "none" }}>
                <img src={medicon} className="home-icon" width="65px" />
                <h4 className="each-title"> Medicine Approval </h4>
                
              </Link>
            </div> */}
      

{features.includes("pharmacy_fulfillment")&& <div className="home-tiles" 
           onClick={() => navigate("/service-request", { state: {from: "dashboard"} })}
          >
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Pharmacy Fulfillment</h5> 

          </div> }
          {/* <div className="home-tiles" 
          // onClick={() => navigate("/hospital/add-bdo")}
          >
            <i className="fa fa-user-md home-icon">
            </i>
            <h5 className="each-title">Appointments</h5>

          </div> */}

          {/* <div className="home-tiles"

            // onClick={() => navigate("/bdo-list")}
            >
            <i className="fa fa-address-card home-icon">
            </i> */}
            {/* <h5 className="each-title">Schedules</h5>

          </div>
          <div className="home-tiles"

            // onClick={() => navigate("/add-sda")}
            >
            <i className="fa fa-user-md home-icon">
            </i>
            <h5 className="each-title">Notifications</h5>

          </div> */} 

          
        </div>

      </div>

    </div>

    </>


  );
}

export default PharmacyDashboard;