import React, { useState, useEffect } from "react";
import "./cart.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import PatientNav from "./PatientNav";
import loginpage from "./assets/img/loginpage.png";
import horizonlogo from "./assets/img/horizonlogo.png";
import { APIURL,TZ } from "../../Global";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Modal, Form, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import LoadingOverlay from "react-loading-overlay";
import { Badge } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from "react-alert";

function RegisterPatient({ setShowNavbarAndFooter }) {
  let isValid = true;
  const [selectedValue, setSelectedValue] = useState("email");
  const [emailPhone, setEmailPhone] = useState("");
  let navigate = useNavigate();

  const [isValidPhone, setIsValidPhone] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [emaildata, setEmailData] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phonedata, setPhoneData] = useState({
    phone: {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
  });
  const alert = useAlert();
  const [error, setError] = useState({
    username: "",
    //email: '',
    phone: "",
    email: "",
  });

  const togglePasswordVisibility = () => {
    setPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleRegisterEmail = () => {
    

     
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(emaildata)) {
          alert.error("Enter a valid email address");
        } else {
          let data = {
            email: emaildata,
          };

          axios
            .post(
              `${APIURL}/api/v1/account/send-otp/`,

              data,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              if (res.data.status === "success") {
                alert.success("OTP sent successfully to given email Id");
                localStorage.setItem("email", emaildata);

                navigate("/otppagepat", { state: { email: emaildata } });
              } else {
              }
            })
            .catch((err) => {});
        }
      
    }
  
    const handleEmailPhone = (event) => {
      event.preventDefault();
      let inputData = event.target.value;
      const emailRegex = /^\S+@\S+\.\S+$/;
      const phoneRegex = /^\d{10}$/;
    
      if (inputData.trim().length > 0) {
        if (emailRegex.test(inputData)) {
          // Valid email format
          setEmailData(inputData); // Assuming you have separate state variables for email and phone
        } else if (phoneRegex.test(inputData)) {
          // Valid phone number format
          setPhoneData({
            phone: {
              ...phonedata.phone,
              rawphone: inputData,
            },

        
          });
        }
        // else{
        //   alert.error("Please Check the data you entered")
        // }
      }
    };
 

  const handleRegisterMobile = () => {
 
      


      
      let data = {
        code: "356",

        mobile_number: phonedata.phone.rawphone,
      };
      axios
        .post(
          `${APIURL}/api/v1/account/send-otp/`,

          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            

            alert.success("OTP sent successfully to your mobile number");
            // localStorage.setItem("phone", phonedata.phone.rawphone);
            navigate("/otppagemob", {
              state: { phone: phonedata.phone.rawphone },
            });
          } else {
          }
        })
        .catch((err) => {});
      
    
    

   
  }


  const handleRegisterUser = () => { 
    
 


 let isValid = true;

 // Reset the error messages
 setUsernameError('');
 setPasswordError('');
 setConfirmPasswordError('');
 setEmailError('');
      if (username !== "undefined") {
        var pattern1 = new RegExp(
          /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){0,18}[a-zA-Z0-9]$/
        );
        if (!pattern1.test(username) || username.length < 4) {
          isValid = false;
          setUsernameError(
            'Special characters and space are not allowed in username and characters limit is 4-20'
          );
        }
      }
      if (emaildata !== "") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(emaildata)) {
        isValid = false;
        setEmailError('Enter a valid email address');
      }
    }

      if (typeof password !== "undefined") {
        let pattern = new RegExp(
          /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!#^%*?&])([a-zA-Z0-9@$#^!%*?&]{8,})$/
        );
        if (!pattern.test(password)) {
          isValid = false;
          setPasswordError(
            'Password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character(@$!#^%*?&) and must not include spaces'
          );
        }
      }
      if (!password || password === "" || password === null) {
        isValid = false;
        setPasswordError('Enter a password');
      }

      // if (phonedata.phone.rawphone.length !== 10) {
      //   isValid = false;
      //   alert.error("Enter valid number");
      // }

      if (
        !confirmPassword ||
        confirmPassword === "" ||
        confirmPassword === null ||
        typeof confirmPassword === "undefined"
      ) {
        isValid = false;
        setConfirmPasswordError("Enter confirm password");
      }

      // if (password && typeof password !== "undefined") {
      //   if (password.length > 0 && password.length < 8) {
      //     isValid = false;
      //     setPasswordError("Password must contain atleast 8 characters");
      //   }
      // }

      if (
        typeof password !== "undefined" &&
        password !== "" &&
        password !== null &&
        typeof confirmPassword !== "undefined" &&
        confirmPassword !== "" &&
        confirmPassword !== null
      ) {
        if (password !== confirmPassword) {
          isValid = false;
          setConfirmPasswordError("Passwords don't match");
        }
      }

      if (isValid && phonedata.phone.rawphone) {
        let data = {
          code: "356",

          mobile_number: phonedata.phone.rawphone,
        };
        axios
          .post(
            `${APIURL}/api/v1/account/send-otp/`,

            data,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.data.status === "success") {
              alert.success("OTP sent successfully to your mobile number");
              // localStorage.setItem("phone", phonedata.phone.rawphone);
              navigate("/otppageuser", {
                state: {
                  phone: phonedata.phone.rawphone,
                  user: username,
                  password: password,
                },
              });
            } else {
            }
          })
          .catch((err) => {});
      }
      else if(isValid && emaildata ){
        let data = {
                 email:emaildata
            };
      axios
        .post(
          `${APIURL}/api/v1/account/send-otp/`,

          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            alert.success("OTP sent successfully to Email Id");
            // localStorage.setItem("phone", phonedata.phone.rawphone);
            navigate("/otppageuser", {
              state: {
                email:emaildata,
                user: username,
                password: password,
              },
            });
          } else {

            alert.error("Please check the data you entered")
          }
        })
        .catch((err) => {});
    }
    

  };

  const handleCheckExisting = (e) => {
    
    setIsValidEmail(true);

    if (emaildata !== "" && name!=="") {

      const data = {
        email: emaildata,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              alert.error("email already registered");
              setIsValidEmail(false);
            } else {

              handleRegisterEmail ();
            }
          } else {
          }
        })
        .catch((err) => {});
    }
    else{
      alert.error("Please fill all fields")
    }
  };

  const handleCheckExistingPhone = (e) => {
    
    setIsValidPhone(true);
    if(phonedata.phone.rawphone!=="" && name!==""&&phonedata.phone.rawphone.length === 10){
      const data = {
        code: phonedata.phone.dialcode,
        mobile_number: phonedata.phone.rawphone,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              alert.error("mobile number already exist!!!");
              setIsValidPhone(false);
            } else {
             handleRegisterMobile();
            }
          } else {
            setIsValidPhone(true);
          }
        })
        .catch((err) => {});
    }
    else{
      alert.error("Enter  valid name and mobile number ")
    }
  };

  const handleCheckExistingPhoneUser = (e) => {
    
    setIsValidPhone(true);
    if(phonedata.phone.rawphone!=="" && username!=="" && phonedata.phone.rawphone.length === 10 && password!=="" && confirmPassword!==""){
      const data = {
        code: phonedata.phone.dialcode,
        mobile_number: phonedata.phone.rawphone,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              alert.error("mobile number already exist!!!");
              setIsValidPhone(false);
            } else {
             handleRegisterUser();
            }
          } else {
            setIsValidPhone(true);
          }
        })
        .catch((err) => {});
    }
    else{
      alert.error("Enter  all fields ")
    }
  };


  const handleEmailChane = (e) => {
    

    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setEmailData(val);

    console.log(emaildata);
  };

  const handlePhoneInput = (value, data, event, formattedValue) => {
    const rawPhone = value.slice(data.dialCode.length);

    const dialcode = value.slice(0, data.dialCode.length);
    setPhoneData((currentstate) => ({
      ...currentstate,
      phone: {
        dialcode: dialcode,
        rawphone: rawPhone,
        mobile_number: value,
      },
    }));
  };

  const handleUserNameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };
  const isValidEmail1 = (email) => {
    // Regular expression pattern for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Return true if the email matches the pattern, otherwise false
    return emailPattern.test(email);
  };
  
  const isValidPhone1 = (phone) => {
    // Regular expression pattern for phone number validation
    const phonePattern = /^[0-9]{10}$/;
    
    // Return true if the phone number matches the pattern, otherwise false
    return phonePattern.test(phone);
  };
  
  const handleCheckExistingData = () => {
 
  
  
      if (isValidEmail1 && emaildata) {
        // Valid email entered, perform email existence check
        const data = { email: emaildata };
    
        axios
          .post(`${APIURL}/api/v1/account/check-user/`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              if (res.data.is_existing_user) {
                alert.error("Email/Phone Already registered");
                setIsValidEmail(false);
                setEmailData("")
                // Email already exists
                // Handle the case where email already exists
              } else {
                handleRegisterUser();
                // Valid email entered and doesn't exist
                // Handle the case where email is valid and doesn't exist
              }
            } else {
              // Error occurred during the API call
              // Handle the error case
            }
          })
          .catch((error) => {
            // Error occurred during the API call
            // Handle the error case
          });
      
    } else if (isValidPhone1 &&phonedata.phone.rawphone ){
      // Valid phone number entered, perform phone number existence check
      const data = {
        code: "356",
        mobile_number: phonedata.phone.rawphone,
      };
    
      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user) {
              alert.error("Email/Phone Already Exist");
              setIsValidPhone(false);
              setPhoneData("")
              // Phone number already exists
              // Handle the case where phone number already exists
            } else {
              handleRegisterUser();
              // Valid phone number entered and doesn't exist
              // Handle the case where phone number is valid and doesn't exist
            }
          } else {
            // Error occurred during the API call
            // Handle the error case
          }
        })
        .catch((error) => {
          // Error occurred during the API call
          // Handle the error case
        });
    }
    else{
      alert.error("Enter valid email or phone number")
    }
 
  };
  

  const handleToggle = (value) => {
    if (selectedValue === value) {
      // Deselect the button if it is clicked again
      setSelectedValue(null);
    } else {
      // Select the button
      setSelectedValue(value);
    }
  };
  useEffect(() => {
    setShowNavbarAndFooter(false);
    return () => setShowNavbarAndFooter(true);
  }, [setShowNavbarAndFooter]);
  return (
    <section className="products-area ptb-70" style={{ marginTop: "2%" }}>
      <div className="container" style={{ borderRadius: "2%" }}>
        <div class="row" style={{ borderRadius: "2%" }}>
          <div class="col-lg-8 col-md-6">
            <div className="vector">
              <div
                className="leftpage"
                style={{
                  backgroundImage: `url(${loginpage})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </div>
          <div className="col-lg-1 col-md-1">
            <div className="vertical-line"></div>
          </div>

          <div class="col-lg-3 col-md-5">
            <div
              className="righttop"
              style={{
                backgroundImage: `url(${horizonlogo})`,
                backgroundSize: "cover",
              }}
            ></div>
            <h5 className="logintitle">Register your Account</h5>

            <ToggleButtonGroup
              type="radio"
              name="options"
              style={{
                marginLeft: "-15%",
                marginTop: "3%",
                backgroundColor: "#6BCBB8",
              }}
            >
              <ToggleButton
                id="tbg-radio-1"
                value={"email"}
                className="btn btn-light toggle-btnsnew toggle-btn-7"
                style={{
                  width: "119px",
                  height: "46px",
                  color: "white",
                  background: selectedValue === "email" ? "#F0A639" : "#6BCBB8",
                  border: "0px",
                  borderRadius: "5px",
                }}
                onChange={() => handleToggle("email")}
              >
                <h6
                  style={{
                    fontFamily: "Titillium Web",
                    fontSize: "14px",
                    marginTop: "5%",
                    marginLeft: "-5%",
                  }}
                >
                  {" "}
                  EMAIL ID
                </h6>
              </ToggleButton>
              <ToggleButton
                id="tbg-radio-2"
                value={"phone"}
                className="btn btn-light toggle-btnsnew toggle-btn-7"
                style={{
                  width: "119px",
                  height: "46px",
                  color: "white",

                  background: selectedValue === "phone" ? "#F0A639" : "#6BCBB8",
                  border: "0px ",
                  borderRadius: "8.08px",
                }}
                onChange={() => handleToggle("phone")}
              >
                <h6
                  style={{
                    fontFamily: "Titillium Web",
                    fontSize: "14px",
                    marginTop: "5%",
                    marginLeft: "-5%",
                  }}
                >
                  {" "}
                  PHONE NUMBER
                </h6>
              </ToggleButton>

              <ToggleButton
                id="tbg-radio-3"
                value={"user"}
                className="btn btn-light toggle-btnsnew toggle-btn-7"
                style={{
                  width: "119px",
                  height: "46px",
                  color: "white",
                  background: selectedValue === "user" ? "#F0A639" : "#6BCBB8",
                  border: "0px",
                  borderRadius: "8.08px",
                }}
                onChange={() => handleToggle("user")}
              >
                <h6
                  style={{
                    fontFamily: "Titillium Web",
                    fontSize: "14px",
                    marginTop: "5%",
                    marginLeft: "-5%",
                  }}
                >
                  USERNAME
                </h6>
              </ToggleButton>
            </ToggleButtonGroup>

            {selectedValue === "email" ? (
              <div
                className="form-group"
                style={{ marginLeft: "-15%", marginTop: "10%" }}
              >
                <label
                  htmlFor="name"
                  style={{ display: "block", fontFamily: "Poppins" }}
                >
                  Name
                </label>
                <div className="input-group" style={{ width: "350px" }}>
                  <input
                    type="text"
                    className="form-control emailname"
                    onChange={handleName}
                    name="name"
                    placeholder="Enter Name"
                  />
                  <span className="insideinput">
                    <i
                      className="fa fa-user"
                      aria-hidden="true"
                      style={{
                        marginLeft: "30%",
                        marginTop: "30%",
                        color: "white",
                      }}
                    ></i>
                  </span>
                </div>
                <label
                  htmlFor="password"
                  style={{
                    display: "block",
                    marginTop: "5%",
                    fontFamily: "Poppins",
                  }}
                >
                  Email Id
                </label>
                <div className="input-group" style={{ width: "350px" }}>
                  <input
                    type="text"
                    className="form-control emailname"
                    name="email"
                    required
                   // onBlur={handleCheckExisting}
                    onChange={(e) => handleEmailChane(e)}
                    placeholder="Email ID"
                  />

                  <span className="insideinput">
                    <i
                      className="fa fa-envelope-o"
                      aria-hidden="true"
                      style={{
                        marginLeft: "30%",
                        marginTop: "30%",
                        color: "white",
                      }}
                    ></i>
                  </span>
                </div>
              </div>
            ) : selectedValue === "phone" ? (
              <div
                className="form-group"
                style={{ marginLeft: "-15%", marginTop: "10%" }}
              >
                <label
                  htmlFor="name"
                  style={{ display: "block", fontFamily: "Poppins" }}
                >
                  Name
                </label>
                <div className="input-group" style={{ width: "350px" }}>
                  <input
                    type="text"
                    className="form-control emailname"
                    onChange={handleName}
                    placeholder="Enter Name"
                  />
                  <span className="insideinput">
                    <i
                      className="fa fa-user"
                      aria-hidden="true"
                      style={{
                        marginLeft: "30%",
                        marginTop: "30%",
                        color: "white",
                      }}
                    ></i>
                  </span>
                </div>
                <label
                  htmlFor="password"
                  style={{
                    display: "block",
                    marginTop: "5%",
                    fontFamily: "Poppins",
                  }}
                >
                  Phone Number
                </label>
                <div className="input-group" style={{ width: "350px" }}>
                  <PhoneInput
                    className="form-control emailname"
                    country={"mt"}
                    onChange={handlePhoneInput}
                    dropdownStyle={{ display: "none" }}
                   //onBlur={handleCheckExistingPhone}
                  />
                  <span className="insideinput">
                    <i
                      className="fa fa-phone"
                      aria-hidden="true"
                      style={{
                        marginLeft: "30%",
                        marginTop: "30%",
                        color: "white",
                      }}
                    ></i>
                  </span>
                </div>
              </div>
            ) : (
              <div
                className="form-group"
                style={{ marginLeft: "-15%", marginTop: "10%" }}
              >
                <label
                  htmlFor="user"
                  style={{ display: "block", fontFamily: "Poppins" }}
                >
                  User Name
                </label>
                <div className="input-group" style={{ width: "350px" }}>
                  <input
                    type="text"
                    className="form-control emailname"
                    onChange={handleUserNameChange}
                    placeholder="Enter Username"
                  />
                  <span className="insideinput">
                    <i
                      className="fa fa-user"
                      aria-hidden="true"
                      style={{
                        marginLeft: "30%",
                        marginTop: "30%",
                        color: "white",
                      }}
                    ></i>

                  </span>
                  {usernameError && <div className="error-message" style={{ color: "red"}}>{usernameError}</div>}
                </div>
                <label htmlFor="password" style={{ display: 'block', marginTop: '5%', fontFamily: 'Poppins' }}>
        Password
      </label>
      <div className="input-group" style={{ width: '350px' }}>
        <input
          type={passwordType}
          className="form-control emailname"
          maxLength="30"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter your password"
        />

        <span className="insideinput" onClick={togglePasswordVisibility}>
          <i
            className={passwordType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'}
            aria-hidden="true"
            style={{ marginLeft: '30%', marginTop: '30%', color: 'white', cursor: 'pointer' }}
          ></i>
        </span>
        {passwordError && <div className="error-message" style={{ color: "red"}}>{passwordError}</div>}
      </div>

      <label htmlFor="confirmPassword" style={{ display: 'block', marginTop: '5%', fontFamily: 'Poppins' }}>
        Confirm password
      </label>
      <div className="input-group" style={{ width: '350px' }}>
        <input
          type={confirmPasswordType}
          className="form-control emailname"
          maxLength="30"
          value={confirmPassword}
          onChange={handleConfirmPassword}
          placeholder="Re-enter password"
        />
 
        <span className="insideinput" onClick={toggleConfirmPasswordVisibility}>
          <i
            className={confirmPasswordType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'}
            aria-hidden="true"
            style={{ marginLeft: '30%', marginTop: '30%', color: 'white', cursor: 'pointer' }}
          ></i>
        </span><br/>
        
                </div>
                {confirmPasswordError && <div className="error-message" style={{ color: "red"}}>{confirmPasswordError}</div>}

                <label
                  htmlFor="password"
                  style={{
                    display: "block",
                    marginTop: "6%",
                    fontFamily: "Poppins",
                  }}
                >
                  Phone Number/Email
                </label>
                <div className="input-group" style={{ width: "350px" }}>
                  <input
                  type="text"
                    className="form-control emailname"
                    maxLength="30"
                    onChange={handleEmailPhone}
                   // onBlur={handleCheckExistingData}
                  />
                  <span className="insideinput">
                    <i
                      className="fa fa-phone"
                      aria-hidden="true"
                      style={{
                        marginLeft: "30%",
                        marginTop: "30%",
                        color: "white",
                      }}
                    ></i>
                  </span>
                  {emailError && <div className="error-message" style={{ color: "red"}}>{emailError}</div>}
                </div>
                
              </div>
            )}

         {selectedValue ==="email" ?   <button
              className="loginbutton"
              style={{ color: "white", fontFamily: "Poppins" }}
              onClick={() => handleCheckExisting()}
            >
              Send OTP
            </button> :

             selectedValue ==="phone" ? 
            <button
            className="loginbutton"
            style={{ color: "white", fontFamily: "Poppins" }}
            onClick={() => handleCheckExistingPhone()}
          >
            Send OTP
          </button> :
           <button
           className="loginbutton"
           style={{ color: "white", fontFamily: "Poppins" }}
           onClick={() => handleCheckExistingData()}
         >
           Send OTP
         </button>}

            <br />

            <div
              className="or-text"
              style={{ marginLeft: "-2%", marginTop: "5%", color: "#C2C2C2" }}
            >
              ----------------------------- OR ----------------------------
            </div>

            <button
              className="registerpat"
              style={{ color: "#1E2772", fontFamily: "Poppins" }}
              onClick={() => navigate("/loginpatient")}
            >
              Login Now
            </button>

            <button
              className="registerpat"
              onClick={() => navigate("/signup")}
              style={{
                color: "#1E2772",
                fontFamily: "Poppins",
                marginTop: "3%",
              }}
            >
              Service Provider Registration
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
export default RegisterPatient;
