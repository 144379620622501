import React,{useState, useEffect} from "react";
import "./changepassworddoctor.css";
import axios from 'axios';

import { Link,useNavigate,useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { APIURL,TZ } from "../../Global";
import BackButton from "../common/BackButton";

function ChangePasswordDoctor(props) {

    const [modalShow, setModalShow] = useState(false);
   const [password, setPassword] = useState("");
      const [confirmPassword, setConfirmPassword] = useState(null);
      const [submitMsg, setSubmitMsg]= useState("");
       const [error,setError]=useState({
   
    password: '',
    confirm_password:"",
   
   });
   const [passwordType, setPasswordType] = useState("password");
   const [passwordType1, setPasswordType1] = useState("password");
  //  const id = props.match.params.id;
  const { state } = useLocation()
const id =state.id;

   const navigate =useNavigate();
   const alert = useAlert();
   const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});
   
   
   useEffect(()=> {
    window.scrollTo(0,0)
  },[])
  
  

    const handlePasswordChange =(e)=>{

    
   	setPassword(e.target.value);
   }
    const handleConfirmPassword =(e)=>{

    setConfirmPassword(e.target.value);
   }

   const handleSubmit=async (e)=>{
    e.preventDefault();
    if(await validate()){
    callSubmit();

  }

}
  const callSubmit=()=>{

   

   	const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); 

   
  const data = {
  	  	password : password
  }

    axios
      .put(`${APIURL}/api/v1/service-provider/doctor/${id}/`, data, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token "+v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert.success('Password changed successfully')
          navigate('/doctormanagement')
        } else {
     
          setSubmitMsg("error");
        }
      })
      .catch((err) => {
       
        setSubmitMsg("error");
      });
  }

const validate=async ()=>{
   
      let errors = {};
      let isValid = true;
    setError({});

 
  

    if (password !== "undefined") {
      let pattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,30}/);
      if (!pattern.test(password)) {
        isValid = false;
        errors["password"] ="The password should be 8-30 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (@$.!#^%*?&_). Spaces are not allowed.";
      }
    }
      if (!password || password==="" || password===null) {
        isValid = false;
        errors["password"] = "Enter password";
      }
  
      if (!confirmPassword || confirmPassword==="" || confirmPassword=== null || typeof confirmPassword === "undefined") {
        isValid = false;
        errors["confirm_password"] = "Enter confirm password";
      }
  
      // if (password && typeof password !== "undefined") {
      //   if(password.length >0 && password.length < 8){
      //       isValid = false;
      //       errors["password"] = "Must contain atleast 8 characters";
      //   }
      // }
  
      if (typeof password !== "undefined" && password!=="" && password!==null && typeof confirmPassword !== "undefined" && confirmPassword!=="" && confirmPassword!== null) {
       if (password !== confirmPassword) {
          isValid = false;
          errors["confirm_password"] = "Passwords don't match";
        }
      }
     
        


    
       await setError(currentstate=>({
            ...currentstate,
            ...errors
          }));
      

      return isValid;
  }
   const togglePasswordVisiblity = (e) => {

    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const togglePasswordVisiblity1 = (e) => {

    if (passwordType1 === "password") {
      setPasswordType1("text")
      return;
    }
    setPasswordType1("password")
  }
 return( 
 <>

<div style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }} className=''>
            <div className='row'>
                <div className="col-lg-2 col-md-2">
                    <h4
                        style={{
                            color: "#245693",
                            marginTop: "8%",
                            fontFamily: "inter",
                            fontWeight: "700",
                            marginLeft: "20px",
                        }}
                    >
                        Users Classifications
                    </h4>
                    <div className="leftdivdeathrepo">
                        
                        <button onClick={e => { navigate("/doctormanagement") }}className="deathreportbutton"  style={{ backgroundColor:"rgb(0, 68, 124)" , color:  "white"  }}>
                            {" "}
                             <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 48 48"><g fill="none"><path d="M0 0h48v48H0z"/><path fill="currentColor" fill-rule="evenodd" d="M34 16c0 5.523-4.477 10-10 10s-10-4.477-10-10S18.477 6 24 6s10 4.477 10 10m-2 0a8 8 0 1 1-16 0a8 8 0 0 1 16 0M17.914 28.855l.011.022l.075.147h11.749c.229-.434.748-1.126 1.251-1.011c1.13.257 2.268.615 3.361 1.056l.033-.016l.011.022l.008.015C38.528 30.762 42 33.596 42 36.57V42H6v-5.43c0-3.775 5.596-7.327 11-8.557c.441-.1.703.42.914.842m14.79 1.728a20 20 0 0 0-1.301-.407l-.446.848H16.793l-.414-.787l-.36.108q-.011.098-.016.224c-.013.345.013.754.07 1.17a8 8 0 0 0 .28 1.281a3 3 0 1 1-1.957.444l-.008-.028a10 10 0 0 1-.297-1.426a10 10 0 0 1-.084-.928c-1.236.528-2.389 1.166-3.355 1.87C8.73 34.356 8 35.668 8 36.57V40h32v-3.43c0-.903-.73-2.215-2.652-3.617a16.6 16.6 0 0 0-2.666-1.562A10.5 10.5 0 0 1 34.434 33H35a1 1 0 0 1 .894.553l1 2c.07.139.106.292.106.447v2a1 1 0 0 1-1 1h-2v-2h1v-.764L34.382 35h-2.764L31 36.236V37h1v2h-2a1 1 0 0 1-1-1v-2c0-.155.036-.308.106-.447l1-2A1 1 0 0 1 31 33h1.362l.037-.124c.094-.321.178-.72.235-1.136c.056-.412.082-.815.07-1.157M17 36c0 .574-.462 1.015-1 1.015s-1-.44-1-1.015c0-.574.462-1.015 1-1.015s1 .44 1 1.015" clip-rule="evenodd"/></g></svg>{" "}
                            &nbsp;&nbsp;Doctor
                        </button>
                        <button className="deathreportbutton" onClick={e => { navigate("/bdomanagement") }} style={{ backgroundColor:  "#F8F8F8", color:  "rgb(0, 68, 124)" }}>
                            {" "}
                              <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4c-3.855 0-7 3.145-7 7c0 2.379 1.21 4.484 3.031 5.75C7.926 18.352 5 22.352 5 27h2c0-4.398 3.191-8.074 7.375-8.844L15 20h2l.625-1.844C21.809 18.926 25 22.602 25 27h2c0-4.648-2.926-8.648-7.031-10.25C21.789 15.484 23 13.379 23 11c0-3.855-3.145-7-7-7m0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5m-1 15l-1 6h4l-1-6z"/></svg>{" "}
                            &nbsp;&nbsp;BDO
                        </button>
                        <button className="deathreportbutton" onClick={e => {  navigate("/pharmacymanagement") }} style={{ backgroundColor:  "#F8F8F8", color:  "rgb(0, 68, 124)" }}>
                            {" "}
                              <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor"><path d="M.5 6.5V10c0 4.56 2.5 8.5 7 10.36v.14l-.123.123a12 12 0 0 1-3.119 2.248l-.758.379v.25h17v-.25l-.758-.38a12 12 0 0 1-3.119-2.247L16.5 20.5v-.14c4.5-1.86 7-5.8 7-10.36V6.5z"/><path d="M10.5 12.5v-3h3v3h3v3h-3v3h-3v-3h-3v-3zm9-12l-6 6h4l4-4V2A1.5 1.5 0 0 0 20 .5z"/></g></svg>{" "}
                            &nbsp;&nbsp;Pharmacist
                        </button>
                        <button className="deathreportbutton" onClick={e => { navigate("/nursemanagement") }} style={{ backgroundColor:  "#F8F8F8", color:  "rgb(0, 68, 124)" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 48 48"><g fill="none"><path d="M0 0h48v48H0z"/><path fill="currentColor" d="M27 13v-2h-2V9h-2v2h-2v2h2v2h2v-2z"/><path fill="currentColor" fill-rule="evenodd" d="m31.32 16.767l1.978-5.606c.536-1.52-.228-3.24-1.829-3.747C29.61 6.824 26.57 6 24 6s-5.61.824-7.47 1.414c-1.6.508-2.364 2.228-1.828 3.747l1.979 5.606a8 8 0 1 0 14.639 0M24 8c-2.24 0-5.029.738-6.865 1.32c-.46.146-.73.658-.547 1.176l2.024 5.734c3.587-1.14 7.189-1.14 10.776 0l2.024-5.734c.183-.518-.087-1.03-.547-1.175C29.029 8.738 26.239 8 24 8m5.806 10.481h-.001a1 1 0 0 1-.156-.045c-3.774-1.415-7.524-1.415-11.298 0a1 1 0 0 1-.157.045a6 6 0 1 0 11.613 0" clip-rule="evenodd"/><path fill="currentColor" d="M34 34h2v2h-2v2h-2v-2h-2v-2h2v-2h2z"/><path fill="currentColor" fill-rule="evenodd" d="M16.879 28S6 31.393 6 35.467V42h36v-6.533C42 31.393 31.121 28 31.121 28l-5.972 4.193a2 2 0 0 1-2.298 0zm14.562 2.22l-5.142 3.61a4 4 0 0 1-4.597 0l-5.143-3.61a38 38 0 0 0-4.227 1.776c-1.296.646-2.482 1.363-3.316 2.092C8.115 34.875 8 35.343 8 35.467V40h32v-4.533c0-.124-.115-.592-1.016-1.38c-.834-.728-2.02-1.445-3.317-2.092a38 38 0 0 0-3.547-1.528q-.376-.14-.679-.248" clip-rule="evenodd"/></g></svg>{" "}
                            &nbsp;&nbsp;Nurse
                        </button>
                        <button className="deathreportbutton" onClick={() => navigate("/carermanagement")} style={{ backgroundColor:  "#F8F8F8", color:  "rgb(0, 68, 124)" }}>
                            {" "}
                             <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M11.28 9.34a1.91 1.91 0 0 0 0-2.77a2.07 2.07 0 0 0-2.85 0L7 8L5.57 6.57a2.07 2.07 0 0 0-2.85 0a1.91 1.91 0 0 0 0 2.77L7 13.5zM7 4.5a2 2 0 1 0 0-4a2 2 0 0 0 0 4"/></svg>{" "}
                            &nbsp;&nbsp;Carer
                        </button>


                        
                    </div>
                </div>
                <div className="col-lg-10 col-md-10">
    <div className="container">
       
       <form className="change-pw-form col-lg-6" onSubmit={handleSubmit}>
       <h4 className="title-of-page"> Change Password </h4>
         <h5 className="title-of-tasks"> Doctor ID : {id} </h5>
         <div className="form-group ">
         <div className="form-control " style={{ backgroundColor: "rgb(232, 240, 254)" }}>
           <input className="box1 " type={passwordType} maxLength='30' placeholder="*Enter new password" value={password} onChange={handlePasswordChange} />
           <span style={{ float: "right" }}>

                {passwordType === "password" ? <i onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity} className="fa fa-eye"></i>}
              </span>
              </div>
           {error.password ?<div className="error-validation-msg"> {error.password}</div>: null} 
           </div>
          <div className="form-group ">
          <div className="form-control " style={{ backgroundColor: "rgb(232, 240, 254)" }}>
           <input className="box1 " type={passwordType1} maxLength='30' placeholder="*Confirm password" value={confirmPassword} onChange={handleConfirmPassword} />
           <span style={{ float: "right" }}>
{passwordType1 === "password" ? <i onClick={togglePasswordVisiblity1} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity1} className="fa fa-eye"></i>}
</span>
</div>
          {error.confirm_password ?<div className="error-validation-msg"> {error.confirm_password}</div>: null} 
                                         
         </div>

  <div className="form-button ">
          <Link to="/doctormanagement"><button className="btn btn-primary btn-col" > Cancel</button></Link>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <button type="submit" className="btn btn-primary btn-col">Update password </button>
  </div>
 </form>
      {submitMsg=== "error" ?
       <h5 style={{ color: "red" }}>
            
            Error in data submission.Please try again!
          </h5>
      :
      null}

    </div>
    </div>
    </div>
    </div>
 </>
);
}
export default ChangePasswordDoctor;