import React,{useState, useEffect, useRef} from 'react';
import axios from "axios";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import { APIURL,TZ } from "../../Global";
import EditAller from './EditAller';
import './observations.css';
import { useAlert } from "react-alert";
function ViewAllerg(props){
  
  const patientId = props.patientId;
  const {data, id} =props
  const alert = useAlert();
const [observationsArray,setObservationsArray]=useState(props.obs);
  const [errorMsg,setErrorMsg]=useState("");
   const [modalShow,setModalShow]=useState(false);
  const [errorSubmit,setErrorSubmit]=useState(false);
  const [editShow, setEditShow]= useState(false)

  const [deleteIndex, setDeleteIndex]= useState('');
  const [deleteShow, setDeleteShow]= useState(false)
  
  const [obsData, setObsData]= useState('')
     
           const [submitMsg,setSubmitMsg] = useState("");

           const [,setGetError]=useState('');
           const allergyDelButtonRef = useRef(null)
           
           useEffect(() => {
           const handleKey = (e) => {

             if ((e.altKey && e.key === 'c') && allergyDelButtonRef.current) {
                 e.preventDefault()
                 setDeleteShow(false)
             }else if ((e.altKey && e.key === 's') && allergyDelButtonRef.current) {
               e.preventDefault()
               allergyDelButtonRef.current.click()
             }else if ((e.key === 'Enter') && allergyDelButtonRef.current) {
               e.preventDefault()
               allergyDelButtonRef.current.click()
             }else if(e.altKey && e.key === "c"){
                setEditShow(false)
             }
           
           }
           document.addEventListener("keydown", handleKey);
           
           return () => {
             document.removeEventListener("keydown", handleKey);
           }
           
         }, []);
           useEffect(()=> {
            setObservationsArray(props.obs)
           },[props.obs])
          
      


const SubmitPopUp=({show,onHide})=> {
 

  return (
    <Modal
      show
      onHide
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{submitMsg}</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button onClick={onHide}>Continue</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}

const DeleteConfirmPopup =(props)=>{
     
 
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
     
      <Modal.Body style={{display:'flex', flexDirection:'column'}}>
      <div><div className="alert-border"><span className="alert-icon">!</span></div></div>
        <label style={{margin:'20px auto', color:'#4e4e4e', fontSize:'18px'}}>Are you sure you want to delete?</label>
        <div className="form-group" style={{ display: 'flex', margin: 'auto' }}>
          <button type="button" className='swirl-butns' style={{ margin: '10px 3px 0px 0px', backgroundColor: '#dc3545' }} onClick={props.onHide}> Cancel </button>
          <button type="submit" className='swirl-butns' style={{ margin: '10px 0px 0px 3px', backgroundColor: '#2b69a3' }} ref={allergyDelButtonRef} onClick={callDelete} >Confirm </button>
        </div>
        </Modal.Body>
    </Modal>
  );
}




const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg!=='' ? "Error:"+ ''+ errorMsg : "Error in Data Submission. Please try again!" } </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}






const renderData=(cellInfo)=> {

    let dateString = observationsArray[cellInfo.index][cellInfo.column.id];

let dateObject = new Date(dateString);

const updatedDate = dateObject.toString();
    return (<div >
      <p> {updatedDate.slice(4,16)}</p>
     </div>)

   }

const renderInfo=(cellInfo)=> {
  let data = observationsArray[cellInfo.index]
  return(<div>
  <p> {data.added_by}</p>
  </div>)
}




const renderEditable=(cellInfo)=> {
  const obsList= observationsArray;

  return (<div >
    <p> {obsList[cellInfo.index][cellInfo.column.id]}{cellInfo.column.id==="duration"? "years":cellInfo.column.id==="master_allergy"?<><br/><span style={{fontSize:"16px",paddingRight:"1%", backgroundColor: "#5587b573", borderRadius:"3px"}}>{" "}{obsList[cellInfo.index].master_allergy_type}{" "}</span></>:""}</p>
   </div>)


}
const renderEditableReaction=(cellInfo)=> {
  const obsList= observationsArray;

  return (<div >
    <p> {obsList[cellInfo.index][cellInfo.column.id].map((item)=>{
      return(<> {item.replace(/_/g, ' ') }<br/></>
       
      )
    })}</p>
   </div>)


}


const editObservations = (cellInfo) => {
  setObsData(observationsArray[cellInfo])
  setEditShow(true)
}

const ObsEditPopup =(props)=>{
  
  return (
       <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        size='lg'
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <h3 className="title-of-tasks header"> Edit Allergy</h3>
       
        <EditAller data={obsData} id={patientId} handleObs={props.handle} allData={observationsArray} />
       
      </Modal.Body>
    </Modal>
  );
}

const dataHandle= () => {
  props.handleObs();
  setEditShow(false)
}


const callDelete=()=>{
  const idToDelete=observationsArray[deleteIndex].id
  const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
       
         axios.delete(`${APIURL}/api/v1/doctor/allergies-details/${idToDelete}/`, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     

     
         .then(res=>{
        
           if(res.status==204){
            alert.success("deleted successfully")
                props.handleObs()
       }
       else{
        alert.error('res.data.message')
       
        
       }

       setDeleteIndex('');
       setDeleteShow(false);
     
     
         })
         .catch(err=>{
           alert.error('in Data Submission. Please try again!')
           
     })

}
const deleteRow=(index)=>{
  const itemToDelete = observationsArray.filter((elt,i)=>{
    return i=== index
  })
 
callDelete(itemToDelete[0].id);

}

const columns = [

{
  Header: "Allergy",
  accessor: "master_allergy",
  Cell: renderEditable,
   sortable:false,
  
   minWidth:180,

},
{
  Header: "Date",
  accessor: "created_at",
  Cell: renderData,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:100,
  width:100,
   sortable:false

},
{
  Header: "Severity",
  accessor: "severity",
  Cell: renderEditable,
   sortable:false,
  
   

},
{
  Header: "Reactions",
  accessor: "reaction",
  Cell: renderEditableReaction,
   sortable:false,
   width:180,

},
{
  Header: "Added By",
  accessor: "doctor_name",
  Cell: renderEditable,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:160,
  width:160,
   sortable:false

},
{
  Header: "Duration",
  accessor: "duration",
  Cell: renderEditable,
   sortable:false,
   minWidth:100,
   width:70

},
{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "Delete Row"><i className="fas fa-trash-alt "></i></span>,
  Cell: props=>{
    return (<>
    <svg   onClick={()=>{setDeleteIndex(props.index);
      setDeleteShow(true);}}xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="red" d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"/></svg>
    &nbsp;&nbsp;&nbsp;
      <svg onClick={()=>{editObservations(props.index)}} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="#0d6efd" d="m21.561 5.318l-2.879-2.879A1.5 1.5 0 0 0 17.621 2c-.385 0-.768.146-1.061.439L13 6H4a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-9l3.561-3.561c.293-.293.439-.677.439-1.061s-.146-.767-.439-1.06M11.5 14.672L9.328 12.5l6.293-6.293l2.172 2.172zm-2.561-1.339l1.756 1.728L9 15zM16 19H5V8h6l-3.18 3.18c-.293.293-.478.812-.629 1.289c-.16.5-.191 1.056-.191 1.47V17h3.061c.414 0 1.108-.1 1.571-.29c.464-.19.896-.347 1.188-.64L16 13zm2.5-11.328L16.328 5.5l1.293-1.293l2.171 2.172z"/></svg>
      </>
   )
  },
  sortable:false,
  filterable:false,
  minWidth:50,
  width:80
},







]
        
return (<>

<>
 
 





<div className="">
{/*<h5 className="title-of-tasks"> Data Table </h5>*/}
<div className="current-table-holder" style={{ marginTop: "1%" }}>
          <ReactTable
            columns={columns}
            data={observationsArray}
            defaultPageSize={5}
            filterable
            resizable
            noDataText={"No data"}
            className="observation-table"
          
          >

          </ReactTable>
</div>
 
    <br/>
    </div>



             <br/>

{submitMsg!=="" && submitMsg!== null ? 
          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {setModalShow(false);
                        setSubmitMsg('');
                      }}

       
      /> : null }
         
      {errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }

    {
      editShow ?
      <ObsEditPopup
        show={editShow}
        onHide={() =>  {setEditShow(false);}}
        handle={dataHandle}
      />: ''
    }


{deleteIndex!=='' ?
    <DeleteConfirmPopup
        show={deleteShow}
        onHide={() =>  {setDeleteShow(false);
                       setDeleteIndex('');}}
       
      />: ''
    }

 </>

             </>

)
}
export default ViewAllerg;