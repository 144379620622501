import React, { useState } from 'react'
import axios from "axios";
import { APIURL, TZ } from "../../Global";
import './hospprofile.css'
import { useAlert } from 'react-alert';
function WebsiteUpdate(props) {
  const alert = useAlert()
  const [website, setWebsite] = useState(props.wb)
  const [error, setError] = useState({
    website: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    if (key === 'website') {
      setWebsite(val)
    }
  }

  const validate = () => {

    // let input = website;
    let errors = {};
    let isValid = true;

    if (website !== "" && website !== null
    ) {
      var regex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      var pattern = new RegExp(regex);
      if (!pattern.test(website)) {
        isValid = false;
        errors["website"] = "Enter a valid url.";

      }
      else {
        submitWebsite();
      }
    }
    setError(currentstate => ({
      ...currentstate, ...errors
    }))
    return isValid;
  }

  const handleContinue = async (e) => {
    e.preventDefault();
    validate()
  }

  const submitWebsite = (e) => {
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '')
    const data = {
      website: website
    }
    axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/`, data, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          alert.success("Updated successfully")
          props.handle();
        } else {
          alert.error(res.data.message || 'Error occured');
        }
      })
      .catch(err => {
        alert.error("error");
      })
  }

  return (
    <div>
      <div className="item"><input className="form-control" type="url" required name="website" value={website} placeholder="https/http://www.example.com" onChange={handleChange} />
        {error.website ? <div className="error-validation-msg"> {error.website}</div> : null}
      </div>
      <br />
      <div className="form-button">
        <button onClick={handleContinue} className="btn btn-primary btn-col" >Update Website</button>
      </div>
    </div>
  )
}

export default WebsiteUpdate
