import React, {useState} from 'react'

function EditRemark(props) {
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;

    const [remarkData, setRemarkData]= useState(props.data)
    const [show, setShow]= useState(false)

    const handleShow =()=> {
        if(show===false){
            setShow(!show)
        }
        else{
            setRemarkData(props.data)
            setShow(!show)
        }
    }

    let data = remarkData
       props.update(data)
    return (
        <div className='flex-col' style={{}}>        
            {
                show ? 
                <textarea 
                type="text" 
                className="form-group text-area remark-edit"
                value= {remarkData}
                name="remarks" 
                style={{border:"1px solid black",backgroundColor:"white"}}
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setRemarkData(e.target.value)
                  //props.update(data)
          } }
            autoComplete="off"
                /> : <p className='form-group text-area remark-edit initial-remarks'>{remarkData!=="null"&&remarkData}<br/>
                {props.url!=="null"&&<a href={props.url}>{props.url}</a>}</p>
            }

{!props.drug&&
<button style={{ width:"100px"}} onClick={handleShow} className={show===false && (login_datas.user_type === "hospital_doctor" ||login_datas.user_type === "hospital_nurse"||login_datas.user_type === "hospital_allied_prof")? 'btn btn-primary btn-square btn-small btn-color': (login_datas.user_type === "hospital_doctor" ||login_datas.user_type === "hospital_nurse"||login_datas.user_type === "hospital_allied_prof") && show===true ? 'btn btn-danger btn-small btn-square':""}>{show===false && (login_datas.user_type === "hospital_doctor" ||login_datas.user_type === "hospital_nurse"||login_datas.user_type === "hospital_allied_prof")  ?'Edit':(login_datas.user_type === "hospital_doctor" ||login_datas.user_type === "hospital_nurse"||login_datas.user_type === "hospital_allied_prof") && show===true ? 'Discard':""}</button>}
            
        </div>
    )
}

export default EditRemark
