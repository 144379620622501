import React, {useState, useEffect, useRef} from 'react'

function AddRemark(props) {
    const [remarkData, setRemarkData]= useState('')
    const saveButtonRef = useRef(null)
      useEffect(() => {
        const handleKey = (e) => {
    
          if (e.altKey && e.key === 's') {
            e.preventDefault()
            saveButtonRef.current.click()
          }else if (e.key === 'Enter') {
            e.preventDefault()
            saveButtonRef.current.click()
          }
        
        }
        document.addEventListener("keydown", handleKey);
        
        return () => {
          document.removeEventListener("keydown", handleKey);
        }
        
      }, []);

      
    let data = remarkData
       props.update(data)

    const handleSave = ()=> {
        props.save()
    }
   

    return (
        <div className='flex-col'>        

                <textarea 
                type="text" 
                className="form-group text-area remark-add"
                value= {remarkData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setRemarkData(e.target.value)
          } }
            autoComplete="off"
          
                />
            <div style={{ marginTop: '20px', display: 'flex', marginLeft:'50%'}}>
        
        <button disabled={remarkData===''} ref={saveButtonRef} onClick={handleSave} className="btn btn-primary btn-col">
            Save
        </button>
        </div>            
        </div>
    )
}

export default AddRemark
