import React, { useContext, useState } from 'react'
import axios from "axios";
import { APIURL, TZ } from "../../Global";
import { useNavigate } from "react-router-dom";
import './hospprofile.css'
import { Button, Modal } from 'react-bootstrap';
import { LoginStatusContext } from "../contexts/LoginStatusContext";
import { useAlert } from 'react-alert';

function NameUpdate(name) {
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginStatusContext);
  const [hname, sethName] = useState(name)
  const [modalShow, setModalShow] = useState(false);
  const [flag, setFlag] = useState(false)
  const navigate = useNavigate();
  const alert = useAlert()
  const [error, setError] = useState({
    name: ''
  });

  const handleChange = (e) => {
    setFlag(true)
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    if (key === 'name') {
      sethName(val)
    }
  }

  const validate = () => {
    //let input = email;
    let errors = {};
    let isValid = true;

    if (name === "") {
      isValid = false;
      errors["name"] = "Enter a name";
    }

    setError(currentstate => ({
      ...currentstate, ...errors
    }))

    return isValid;
  }

  const handleContinue = async () => {

    if (validate()) {
      submitName()
    }
  }
  const callLogout = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/account/logout/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
        } else {
        }
      })
      .catch((err) => { });

    navigate("/weborderig");
  };
  const handleLogout = () => {
    callLogout();
    setIsLoggedIn(false);

    sessionStorage.removeItem("role");
    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("usertoken");
    sessionStorage.removeItem("usertype");
    sessionStorage.removeItem("logindatas");
  };


  const submitName = (e) => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const data = {
      name: hname
    }

    axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/`, data, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          setFlag(false)
          alert.success(res.data.message || 'Updated successfully')
          handleLogout()
        } else {
          alert.error(res.data.message || 'Error occured')

        }

      })
      .catch(err => {
        alert.error('Error')

      })

  }

  const ConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div><div className="alert-border"><span className="alert-icon">!</span></div></div>
          <label style={{ margin: '20px auto', fontSize: '18px', color: '#4e4e4e' }}> You will be Logged Out after Updating Hospital Name. Are you sure to Continue? </label>
          <div className="form-group" style={{ display: 'flex', margin: 'auto' }}>
            <button type="button" className='swirl-butns' style={{ margin: '10px 4px 0px 0px', backgroundColor: '#dc3545' }} onClick={props.onHide}> Cancel </button>
            <button type="submit" className='swirl-butns' style={{ margin: '10px 0px 0px 4px', backgroundColor: '#2b69a3' }} onClick={props.onCall}>Confirm </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div>
      <div className="item"><input className="form-control" type="text" maxLength="200"
        name="name" value={hname.name}
        onChange={handleChange} />

        {error.name ? <div className="error-validation-msg"> {error.name}</div> : null}

      </div>
      <br />
      <div className="form-button">
        <button disabled={!flag} onClick={() => setModalShow(true)} className="btn btn-primary btn-col" >Update Name</button>
      </div>

      {
        modalShow ?
          <ConfirmPopUp
            show={modalShow}
            onCall={() => { handleContinue(); setModalShow(false) }}
            onHide={() => setModalShow(false)} /> : null
      }

    </div>
  )
}

export default NameUpdate
