import { APIURL,TZ } from "./Global";
import axios from "axios";
import img from "./assets/images/felixa_logo.png";
import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./modules/login/LoginPage";
import ForgotPassword from "./modules/login/ForgotPassword";
import InputOtp from "./modules/login/InputOtp";
import LogoutPage from "./modules/login/LogoutPage";
import HomePage from "./modules/home/HomePage";
import StaffDashboard from "./modules/staff/StaffDashboard";
import Navbar from "./modules/common/Navbar";
import ManagerDashboard from "./modules/Manager/ManagerDashboard";
import AdmissionAdmin from "./modules/Hospital/Admission/AdmissionPatient"; 
import Report from "./modules/BDO/AdmissionReport/Report";
import Footer from "./modules/common/Footer";
import RequireAuth from "./modules/registration/RequireAuth";
import ServiceProviderRegister from "./modules/registration/ServiceProviderRegister";
import { LoginProvider } from "./modules/contexts/LoginContext";
import { LoginStatusProvider } from "./modules/contexts/LoginStatusContext";
import { HospLoginProvider } from "./modules/contexts/HospitalLoginContext";
import { RegProvider } from "./modules/contexts/RegistrationContext";
import HospitalPending from "./modules/Hospital/HospitalPending";
import HospitalDashboard from "./modules/Hospital/HospitalDashboard";
import PageNotFound from "./modules/errorPage/PageNotFound";
import ChangePasswordManager from "./modules/Manager/ChangePasswordManager";
import AddBdo from "./modules/BDO/addbdo";
import BdoList from "./modules/BDO/BdoList";
import Manageradd from "./modules/Manager/Manageradd";
import ManagerList from "./modules/Manager/ManagerList";
import PatientDashboard from "./modules/patient/PatientDashboard";
import RegisteredHospitals from "./modules/staff/RegisteredHospitals";
import DeregisteredHospitals from "./modules/staff/DeregisteredHospitals";
import HospitalDetails from "./modules/staff/HospitalDetails";
import ServiceDetails from "./modules/staff/service/ServiceDetails"
import CreateAppUserDoctor from "./modules/doctor/CreateAppUserDoctor";
import CreateAppUserBdo from "./modules/BDO/CreateAppUserBdo/CreateAppUserBdo";
import CreateAppManager from "./modules/Manager/CreateAppUsermanager/CreateAppManager";
import HospitalUpdateManager from "./modules/Manager/HospitalUpdateManager";
import "react-datepicker/dist/react-datepicker.css";
import { ProfileProvider } from "./modules/contexts/ProfileContext";
import HospProfile from "./modules/HospProfile/HospProfile";
import AddDoctor from "./modules/doctor/AddDoctor";
import DoctorsList from "./modules/doctorsList/DoctorsList"
import EditDoctor from "./modules/doctor/EditDoctor";
import DeleteDoctor from "./modules/doctor/DeleteDoctor";
import SuspendDoctor from "./modules/doctor/SuspendDoctor";
import ChangePasswordDoctor from "./modules/doctor/ChangePasswordDoctor";
import Changepasswordmanager from "./modules/Manager/ChangePasswordManager";
import DoctorDashboard from "./modules/doctor/DoctorDashboard";
import BDODashboard from "./modules/BDO/BDODashboard";
import BdoFeedback from "./modules/BDO/BdoFeedback";

import AddPharmacyUser from "./modules/PharmacyUser/AddPharmacyUser";
import PharmacyUserList from "./modules/PharmacyUser/PharmacyUserList";
import CreatePharmacyUser from "./modules/PharmacyUser/CreatePharmacyUser"
import UpdatePharmacyUser from "./modules/PharmacyUser/UpdatePharmacyUser"
import HospitalUpdateBdo from "./modules/BDO/HospitalUpdateBdo";

import AdmissionChairman from "./modules/HospitalAdmission/AdmissionChairman";

import AddNurse from "./modules/Nurses/addnurse";
import CarerList from "./modules/Carer/CarerList";
import AddCarer from "./modules/Carer/addcarer";

import { ConfirmationProvider } from "./modules/contexts/SubmitConfirmationContext"
import NurseList from "./modules/Nurses/NurseList";
import HospitalUpdateNurse from "./modules/Nurses/HospitalUpdateNurse";
import HospitalUpdateCarer from "./modules/Carer/HospitalUpdateCarer";
import CreateAppUserNurse from "./modules/Nurses/CreateAppUserNurse/CreateAppUserNurse";
import CreateAppUserCarer from "./modules/Carer/CreateAppUserCarer/CreateAppUserCarer";
import MainServices from "./modules/staff/service/MainServices";
import Approvalpage from "./modules/staff/ApprovalImage/Approvalpage";
import MasterServices from "./modules/BDO/SPServices/MasterServices";
import SelectedServices from "./modules/BDO/SPServices/SelectedServices";
import Scan from "./modules/staff/AddScanTest/Scan";
import Test from "./modules/staff/AddLabTest/Test";
import ServiceOrderList from "./modules/staff/service/ServiceOrdersList";
import ServiceOrderReqDetails from "./modules/staff/service/ServiceOrderReqDetails";
import Configuration from "./modules/staff/Configuration/Configuration";
import ConfigDash from "./modules/ConfigDashboard/Config";
import AdminResetPwd from "./modules/ConfigDashboard/AdminResetPwd";
// import ForgotPassword from "./modules/Forgot_password/ForgotPassword";
import UserListDr from "./modules/HospSearchPatient/UserListingDr";

import PatientRecordSVP from "./modules/HospSearchPatient/PatientRecordSVP";
import ResetPassword from "./modules/login/ResetPassword";
import EmailConfirmation from "./modules/registration/EmailConfirmation";
import ChangePasswordHospitalBdo from "./modules/BDO/ChangePasswordHospitalBdo";
import ChangePasswordHospitalNurse from "./modules/Nurses/ChangePasswordHospitalNurse";
import ChangePasswordHospitalCarer from "./modules/Carer/ChangePasswordHospitalCarer";

import ChangePasswordHospitalPharm from "./modules/PharmacyUser/ChangePasswordHospitalPharm";

import ObservationSymptomsBO from "./modules/staff/ObservationAndSymptoms/ObservationSympotmsBO";
import Allergy from "./modules/staff/AddAllergy/Allergy";
import Misc from "./modules/staff/AddMisc/Misc";
import PharmMedicine from "./modules/staff/AddMedicines/PharmMedicine";
import PrimaryDiscease from "./modules/staff/AddPrimaryDiscease/PrimaryDiscease";
import MedicineApprove from "./modules/staff/MedicineApproval/MedicineApprove";
import Comorbidites from "./modules/staff/AddComobridities/Comorbidites"
import ProcedureList from "./modules/staff/ProcedureList/ProcedureList";
import ProcedureRequests from "./modules/ProcedureFulfillment/ProcedureRequests";
import ProcedureNamesBdo from "./modules/ProcedureFulfillment/ProcedureNamesBdo";
import ProcedureRequestsBdo from "./modules/ProcedureFulfillment/ProcedureRequestsBdo";
import LabDashboard from "./modules/LabSP/LabDashboard";
import PharmDashboard from "./modules/PharmacySP/PharmDashboard";
import PharmacyDashboard from "./modules/Pharmacy/PharmacyDashboard";

import ErrorNotification from "./modules/registration/ErrorNotification";
import ServiceRequests_doc from "./modules/ServiceRequests/ServiceRequests_doc";
import ServiceHistoryLab from "./modules/Laboratory/ServiceHistoryLab";

import ServiceHistoryNurse from "./modules/Nurse/ServiceHistoryNurse";
import ServiceHistoryPharm from "./modules/Pharmacy/ServiceHistoryPharm";
import ServiceRequests from "./modules/BDO/ServiceFulfillment/ServiceRequests";
import ServiceHistory from "./modules/BDO/ServiceFulfillment/ServiceHistory";
import WebNotifications from "./modules/WebNotifications/WebNotifications";
import NotificationAll from "./modules/WebNotifications/NotificationAll";
// import NotificationsGeneral from "./modules/WebNotifications/NotificationsGeneral";
import WebNotificationsBdo from "./modules/WebNotifications/WebNotifications_Bdo";
import WebNotificationsDr from "./modules/WebNotifications/WebNotifications_Dr";
import Profile from "./modules/common/Profile/Profile"
import HospRegisterPatient from "./modules/BDO/HospRegisterPatient";
import PaymentRegistration from "./modules/BDO/PaymentRegistration";
import Payment from "./modules/BDO/Payments/Payments";
import Reports from "./modules/BDO/Reports/Reports";
// import InputOtp from "./modules/login/InputOtp";
import PatientsMessage from "./modules/staff/PatientsMessage";
import ServiceCenter from "./modules/staff/Service Center/ServiceCenter";
//import ServiceCenter from "./modules/staff/ServiceCenter";
import CartViewPage from "./modules/staff/CartViewPage";
import ConsumableList from "./modules/staff/Consumable/ConsumableList";
import PharmacyServiceFulfillment from "./modules/Pharmacy/PharmacyServiceFulfillment";

import NurseServiceFulfillment from "./modules/Nurse/NurseServiceFulfillment";
import ScheduleList from "./modules/doctor/Schedule/ScheduleList"
// import Patientordering from "./modules/patient/Patientordering";
import WebOrdering from "./modules/patient/WebOrdeing";
import RazorpayPage from "./modules/patient/RazorpayPage";
import ServicesNew from "./modules/patient/ServicesNew";
import Contact from "./modules/patient/Contact";
import Categories from "./modules/patient/Categories";
import RegisterPatient from "./modules/patient/RegisterPatient";
import Checklist from "./modules/patient/Checklist";
import LabServiceFulfillment from "./modules/Laboratory/LabServiceFulfillment";

import NurseDashboard from "./modules/Nurse/NurseDashboard";
import ServiceHistoryDoc from "./modules/ServiceRequests/ServiceHistoryDoc";
import ServiceHistoryDoc1 from "./modules/ServiceRequests/ServiceHistoryDoc1";
import VideoCall from "./modules/videocall/VideoCall";
import LoginPagePatient from "./modules/patient/LoginPagePatient";
import FollowUp from "./modules/patient/FollowUp";
import AppUserDash from "./modules/ElderCare/AppUserDash";
import CartPage from "./modules/patient/CartPage";
import DealsPage from "./modules/patient/DealsPage";
import OTPpage from "./modules/patient/OTPpage";
import OTPpageMobile from "./modules/patient/OTPpageMobile";
import OTPpageuser from "./modules/patient/OTPpageuser";
import NewArrivalDeals from "./modules/patient/NewArrivalDeals";
import Promotions from "./modules/BDO/SPServices/webordering/Promotions";
import SearchResult from "./modules/patient/SearchResult";
import CreateAcconuntPatient from "./modules/patient/CreateAcconuntPatient";
import CreateAccountMobile from "./modules/patient/CreateAccountMobile";
import CreateAccountUser from "./modules/patient/CreateAccountUser";
import EmailOTPLogin from "./modules/patient/EmailOTPLogin";
import MobileOTPLogin from "./modules/patient/MobileOTPLogin";
import SkipPage1 from "./modules/patient/SkipPage1";
import SkipPage2 from "./modules/patient/SkipPage2";
import SkipPage3 from "./modules/patient/SkipPage3";
import SearchNavigation from "./modules/patient/SearchNavigation";
import Appointment from "./modules/patient/Appointment/Appointment";
import BookingDetails from "./modules/patient/Appointment/BookingDetails";
import HospitalManagement from "./modules/Hospital/HospitalManagement";
import ProceedPage from "./modules/patient/ProceedPage";
import PaymentPage from "./modules/patient/PaymentPage";
import Summarypage from "./modules/patient/Summarypage";
import MyOrders from "./modules/patient/Profile/MyOrders";

import AssignPatient from "./modules/HospSearchPatient/AssignPatient";
import TreatPlanDetails from "./modules/HospSearchPatient/TreatmentPlan/TreatPlanDetails";

import PersonalInventory from "./modules/BDO/Inventory/PersonalInventory";
import WoundDoc from "./modules/BDO/woundDocumentation/woundDoc";
import WoundReport from "./modules/BDO/woundDocumentation/Report";
import DeathReport from "./modules/BDO/DeathCertificate/DeathReport";
import DeathReportManager from "./modules/Manager/DeathCertificate/DeathReport";
import VolunteerRegistration from "./modules/registration/VolunteerRegistration";
import Volunteers from "./modules/BDO/Volunteers/Volunteers";
import VisitorRegistration from "./modules/Visitor/VisitorRegistration";
import Visitor from "./modules/Visitor/Visitor";

import PatientNavigation from "./modules/HospSearchPatient/PatientNavigation";
import AdminHome from "./modules/AdminDash/admin/views/AdminHome";
import NurseInventory from "./modules/Nurse/NurseInventory";
import NurseOrders from "./modules/Nurse/NurseOrders";
import UserManagement from "./modules/Manager/UserManagement";
import UserManagementBDO from "./modules/Manager/UserManagementBDO";
import UserManagementNurse from "./modules/Manager/UserManagementNurse";
import UserManagementPharm from "./modules/Manager/UserManagementPharmacy";
import UserManagementCarer from "./modules/Manager/UserManagementCarer";


function App() {
  const [showNavbarAndFooter, setShowNavbarAndFooter] = useState(true);
  const [cartData, setCartData] = useState([]);
  const updateCartData = () => {
    // const tokenString = sessionStorage.getItem("usertoken");


    // let v2 = tokenString.replace(
    //     /["]+/g,
    //     ""
    // );
    // // Make API call to retrieve updated cart data
    // axios.get(`${APIURL}/api/v1/patient/cart/`, {
    //   headers: { Authorization: "Token " + v2 },
    // })
    //   .then((res) => {
    //     setCartData(res.data.cart_items);
    //   })
    //   .catch((err) => {
    
    //   });
  }
  useEffect(() => {

    axios
      .get(`${APIURL}/api/v1/account/customer-info/?${TZ}`, {
        headers: {},
      })

      .then((res) => {

        if (res.data.status === "success") {
          sessionStorage.setItem("project_type", res.data.project_type);
            document.getElementById("favicon").setAttribute("href", res.data.app_logo)
            document.getElementById('title').innerHTML = res.data.message.app_name ? res.data.message.app_name : "Felixa Care";
        }
        else {
          document.getElementById("favicon").setAttribute("href", img)
        }
      })
  }, []);

  return (
    <>

      <BrowserRouter>
      <ConfirmationProvider>
        <RegProvider>
          <LoginStatusProvider>
            <HospLoginProvider>
              <ProfileProvider>
                <LoginProvider>
                 
                 {showNavbarAndFooter &&  <Navbar />}<br/><br/><br/>
                  <div className="test">


                    <Routes >
                      
                      <Route exact path="/" element={<RequireAuth><HomePage /></RequireAuth>} />
                      <Route exact path="/Hospital" element={<RequireAuth type="hospital"><HospitalDashboard /></RequireAuth>} />
                      <Route exact path="/AppUser"  element={<RequireAuth type="appuser"><AppUserDash/></RequireAuth>} />
                      <Route exact path="/hospitallist" element={<RequireAuth type="staff"><RegisteredHospitals /></RequireAuth>} />
                      <Route exact path="/Deregistered_splist" element={<RequireAuth type="staff"><DeregisteredHospitals /></RequireAuth>} />
                      <Route exact path="/servicelist" element={<RequireAuth type="staff"><MainServices /></RequireAuth>} />
                      <Route exact path="/approvalpage" element={<RequireAuth type="staff"><Approvalpage /></RequireAuth>} />
                      <Route exact path="/personal-inventory" element={<PersonalInventory />} />
                      <Route exact path="/hospital-details/:id" element={<RequireAuth type="staff"><HospitalDetails /></RequireAuth>} />
                      <Route exact path="/service-details/:id" element={<RequireAuth type="staff"><ServiceDetails /></RequireAuth>} />
                      <Route exact path="/login" element={<LoginPage />} />
                      <Route exact path="/forgot-password" element={<ForgotPassword />} />
                      <Route exact path="/input-otp" element={<InputOtp />} />
                      <Route exact path="/reset-password" element={<ResetPassword />} />
                      <Route exact path="/confirm-email" element={<EmailConfirmation />} />
                      <Route exact path="/signup" element={<ServiceProviderRegister />} />
                      <Route exact path="/volunteer" element={<VolunteerRegistration/>}/>
                      <Route exact path="/volunteers" element={<Volunteers/>}/>
                   
                      <Route exact path="/proceedpage" element={<ProceedPage setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/skippage1" element={<SkipPage1 setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/skippage2" element={<SkipPage2 setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/skippage3" element={<SkipPage3 setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/loginpatient" element={<LoginPagePatient setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/registerpat" element={<RegisterPatient setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/createaccountpat" element={<CreateAcconuntPatient setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/createccountmobile" element={<CreateAccountMobile setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/createaccountuser" element={<CreateAccountUser setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/otppagepat" element={<OTPpage setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/otppagemob" element={<OTPpageMobile setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/otppageuser" element={<OTPpageuser setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/emailotplogin" element={<EmailOTPLogin setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/phoneotplogin" element={<MobileOTPLogin setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/ErrorNotification" element={<ErrorNotification />} />
                      <Route exact path="/logout" element={<LogoutPage />} />
       
                      <Route exact path="/admissionreport" element={<Report />} />
                  
 <Route exact path="/admissionadmin" element={<RequireAuth type="manager"><AdmissionAdmin /></RequireAuth>} />
 <Route exact path="/admissionchairman" element={<RequireAuth type="hospital"><AdmissionChairman /></RequireAuth>} />
                      <Route exact path="/patient" element={<RequireAuth type="patient"><WebOrdering setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> </RequireAuth>} />
                      <Route exact path="/config_admin" element={<RequireAuth type="config_admin"><ConfigDash /></RequireAuth>} />
                      <Route exact path="/admin_reset_pwd" element={<RequireAuth type="config_admin"><AdminResetPwd /></RequireAuth>} />
                      <Route exact path="/hospital_pending" element={<RequireAuth type="hospital_pending"><HospitalPending /></RequireAuth>} />
                      {/* <Route exact path="/LaboratoryPending" element={<RequireAuth type="LaboratoryPending"><LaboratoryPending /></RequireAuth>} /> */}
                      <Route exact path="/staff" element={<RequireAuth type="staff"><StaffDashboard /></RequireAuth>} />
                      <Route exact path="/404" element={<PageNotFound />} />
                      <Route exact path="/hospitalmanagement" element={<RequireAuth type="manager"><HospitalManagement /></RequireAuth>} />
                      <Route exact path="/add-bdo" element={<RequireAuth type="manager"><AddBdo /></RequireAuth>} />
                      <Route exact path="/bdo-list" element={<RequireAuth type="manager"><BdoList /></RequireAuth>} />
                     
                      <Route exact path="/add-manager" element={<RequireAuth type="hospital"><Manageradd /></RequireAuth>} />
                      <Route exact path="/manager-list" element={<RequireAuth type="hospital"><ManagerList /></RequireAuth>} />
                      <Route exact path="/create-doctor" element={<RequireAuth type="manager"><CreateAppUserDoctor /></RequireAuth>} />
                      <Route exact path="/create-bdo" element={<RequireAuth type="manager"><CreateAppUserBdo /></RequireAuth>} />
                    
                      <Route exact path="/update-bdo" element={<RequireAuth type="manager"><HospitalUpdateBdo /></RequireAuth>} />
                      <Route exact path="/create-manager" element={<RequireAuth type="hospital"><CreateAppManager /></RequireAuth>} />
                      <Route exact path="/update-manager" element={<RequireAuth type="hospital"><HospitalUpdateManager /></RequireAuth>} />
                      <Route exact path="/cartview" element={<CartViewPage />} />
                      
                      <Route exact path="/changepassword-bdo" element={<RequireAuth type="manager"><ChangePasswordHospitalBdo /></RequireAuth>} />
                     
                      <Route exact path="/changepassword-manager" element={<RequireAuth type="hospital"><ChangePasswordManager /></RequireAuth>} />
                     
                     
                      <Route exact path="/changepassword-carer" element={<RequireAuth type="manager"><ChangePasswordHospitalCarer /></RequireAuth>} />
                      <Route exact path="/changepassword-nurse" element={<RequireAuth type="manager"><ChangePasswordHospitalNurse /></RequireAuth>} />
                     
                      <Route exact path="/changepassword-pharmacyuser" element={<RequireAuth type="manager"><ChangePasswordHospitalPharm /></RequireAuth>} />
                     
                      <Route exact path="/profile" element={<HospProfile />} />
                      {/* <Route exact path="/patientordering" element={<Patientordering />} /> */}
                      {/* <Route exact path="/webordering" element={<WebOrdering  setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>}  /> */}
                      <Route exact path="/contact" element={<Contact  setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>}  />
                      <Route exact path="/categories" element={<Categories setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)} updateCartData={updateCartData} />}  />
                      <Route exact path="/servicespatient" element={<ServicesNew setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)} updateCartData={updateCartData} />} />
                      <Route exact path="/cartpage" element={<CartPage/>}  />
                      <Route exact path="/paymentpage" element={<PaymentPage/>}  />
                      <Route exact path="/summarypage" element={<Summarypage/>}  />
                      <Route exact path="/dealspage" element={<DealsPage setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)} updateCartData={updateCartData} />} />
                      <Route exact path="/newarrivaldeals" element={<NewArrivalDeals  setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>}  />
                      <Route exact path="/checklist" element={<Checklist  setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>}  />
                      <Route exact path="/razor" element={<RazorpayPage  setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>}  />
                      <Route exact path="/followup" element={<FollowUp/>} />
                      <Route exact path="/adddoctor" element={<RequireAuth type="manager"><AddDoctor /></RequireAuth>} />
                      <Route exact path="/doctormanagement" element={<RequireAuth type="manager"><UserManagement /></RequireAuth>} />
                      <Route exact path="/bdomanagement" element={<RequireAuth type="manager"><UserManagementBDO /></RequireAuth>} />
                      <Route exact path="/pharmacymanagement" element={<RequireAuth type="manager"><UserManagementPharm /></RequireAuth>} />
                      <Route exact path="/nursemanagement" element={<RequireAuth type="manager"><UserManagementNurse /></RequireAuth>} />
                      <Route exact path="/carermanagement" element={<RequireAuth type="manager"><UserManagementCarer /></RequireAuth>} />
                      <Route exact path="/feedback" element={<RequireAuth type="bdo"><BdoFeedback /></RequireAuth>} />
                      <Route exact path="/doctorslist" element={<RequireAuth type="manager"><DoctorsList /></RequireAuth>} />
                      <Route exact path="/update-doctor" element={<RequireAuth type="manager"><EditDoctor /></RequireAuth>} />
                     
                      <Route exact path="/deletedoctor/:id" element={<RequireAuth type="manager"><DeleteDoctor /></RequireAuth>} />
                      <Route exact path="/suspenddoctor/:id" element={<RequireAuth type="manager"><SuspendDoctor /></RequireAuth>} />
                      <Route exact path="/changepassword-doctor" element={<RequireAuth type="manager"><ChangePasswordDoctor /></RequireAuth>} />
                      <Route exact path="/doctor/profile" element={<Profile />} />
                      <Route exact path="/Laboratory" element={<RequireAuth type="lab"><LabDashboard /></RequireAuth>} />
                      <Route exact path="/Pharmacy" element={<RequireAuth type="pharmacy"><PharmDashboard /></RequireAuth>} />
                      <Route exact path="/hospital_doctor" element={<RequireAuth type="doctor"><DoctorDashboard /></RequireAuth>} />
                      <Route exact path="/doctor_schedule" element={<RequireAuth type="doctor"><ScheduleList /></RequireAuth>} />
                      <Route exact path="/hospital_pharmacist" element={<RequireAuth type="pharmacy"><PharmacyDashboard /></RequireAuth>} />
                    
                     
                      <Route exact path="/hospital_nurse" element={<RequireAuth type="nurse"><NurseDashboard /></RequireAuth>} />
                   
                      <Route exact path="/visitor_registration" element={<VisitorRegistration/>} />
                      <Route exact path="/visitor_requests" element={<Visitor/>} />
                      <Route exact path="/hospital_BDO" element={<RequireAuth type="bdo"><BDODashboard /></RequireAuth>} />
                     
                      <Route exact path="/hospital_manager" element={<RequireAuth type="manager"><ManagerDashboard /></RequireAuth>} />
                      <Route exact path="/wound_chart" element={<WoundDoc />} />
                      <Route exact path="/monitor" element={<AdminHome />} />
                      <Route exact path="/wound_report" element={<WoundReport />} />
                      
                      <Route exact path="/add-Pharm-user" element={<RequireAuth type="manager"><AddPharmacyUser /></RequireAuth>} />
                      <Route exact path="/pharmacyusers-list" element={<RequireAuth type="manager"><PharmacyUserList /></RequireAuth>} />
                      <Route exact path="/create-pharmacyuser" element={<RequireAuth type="manager"><CreatePharmacyUser /></RequireAuth>} />
                      <Route exact path="/update-pharmacyuser" element={<RequireAuth type="manager"><UpdatePharmacyUser /></RequireAuth>} />
                      <Route exact path="/configuration" element={<RequireAuth type="staff"><Configuration /></RequireAuth>} />
                      <Route exact path="/patient-record/id/:id/mob/:mob" element={<PatientRecordSVP />} />
                      <Route exact path="/patient-navigation/id/:id/mob/:mob" element={<PatientNavigation />} />
                      <Route exact path="/treat-plan/:id" element={<TreatPlanDetails />} />
                      
                     
                      <Route exact path="/add-nurse" element={<RequireAuth type="manager"><AddNurse /></RequireAuth>} />
                      <Route exact path="/add-carer" element={<RequireAuth type="manager"><AddCarer /></RequireAuth>} />
                      <Route exact path="/nurse-list" element={<RequireAuth type="manager"><NurseList /></RequireAuth>} />
                      <Route exact path="/carer-list" element={<RequireAuth type="manager"><CarerList /></RequireAuth>} />
                      <Route exact path="/update-nurse" element={<RequireAuth type="manager"><HospitalUpdateNurse /></RequireAuth>} />
                      <Route exact path="/update-carer" element={<RequireAuth type="manager"><HospitalUpdateCarer /></RequireAuth>} />
                      <Route exact path="/create-nurse" element={<RequireAuth type="manager"><CreateAppUserNurse /></RequireAuth>} />
                      <Route exact path="/create-carer" element={<RequireAuth type="manager"><CreateAppUserCarer /></RequireAuth>} />
                      <Route exact path="/forgot-password" element={<ForgotPassword />} />
                      <Route exact path="/patientlist" element={<UserListDr />} />
                      <Route exact path="/assignpatient" element={<AssignPatient />} />
                     

                      <Route exact path="/master-services" element={<RequireAuth type="bdo"><MasterServices /></RequireAuth>} />
                      <Route exact path="/selected-services" element={<RequireAuth type="bdo"><SelectedServices /></RequireAuth>} />
                      <Route exact path="/Scantest" element={<RequireAuth type="staff"><Scan /></RequireAuth>} />
                      <Route exact path="/Labtest" element={<RequireAuth type="staff"><Test /></RequireAuth>} />
                      <Route exact path="/observation-symptoms" element={<RequireAuth type="staff"><ObservationSymptomsBO /></RequireAuth>} />
                      <Route exact path="/allergy" element={<RequireAuth type="staff"><Allergy /></RequireAuth>} />
                      <Route exact path="/misc" element={<RequireAuth type="staff"><Misc /></RequireAuth>} />
                      <Route exact path="/medicine" element={<RequireAuth type="pharmacy"><PharmMedicine /></RequireAuth>} />
                      <Route exact path="/primary-disease" element={<RequireAuth type="staff"><PrimaryDiscease /></RequireAuth>} />
                      <Route exact path="/comorbidities" element={<RequireAuth type="staff"><Comorbidites /></RequireAuth>} />
                      <Route exact path="/procedures" element={<RequireAuth type="staff"><ProcedureList /></RequireAuth>} />
                      <Route exact path="/consumablelist" element={<RequireAuth type="staff"><ConsumableList /></RequireAuth>} />
                      <Route exact path="/procedure-request" element={<ProcedureRequests />} />
                      <Route exact path="/procedure-names-bdo" element={<ProcedureNamesBdo />} />
                      <Route exact path="/procedure-requests-bdo" element={<ProcedureRequestsBdo />} />
                      <Route exact path="/service_request" element={<RequireAuth type="bdo"><ServiceRequests /></RequireAuth>} />
                      <Route exact path="/promotions" element={<RequireAuth type="bdo"><Promotions /></RequireAuth>} />
                      <Route exact path="/death_report" element={<DeathReport />} />
                      <Route exact path="/deathreport" element={<RequireAuth type="manager"><DeathReportManager /></RequireAuth>} />
                      <Route exact path="/service_history" element={<RequireAuth type="bdo"><ServiceHistory /></RequireAuth>} />
                      <Route exact path="/register-patient" element={<RequireAuth type="bdo"><HospRegisterPatient/></RequireAuth>} />
                      <Route exact path="/paymentreg" element={<RequireAuth type="bdo"><PaymentRegistration/></RequireAuth>} />
                      <Route exact path="/service_history_doc" element={<ServiceHistoryDoc />} />
                      <Route exact path="/service_history_doc1" element={<ServiceHistoryDoc1 />} />
                      <Route exact path="/service_history_lab" element={<ServiceHistoryLab />} />
                      <Route exact path="/medlist" element={<MedicineApprove />} />
                      <Route exact path="/nurseInventory" element={<NurseInventory />} />
                      <Route exact path="/nurseOrders" element={<NurseOrders />} />
                      <Route exact path="/service_history_pharm" element={<ServiceHistoryPharm />} />
                      <Route exact path="/service_history_nurse" element={<ServiceHistoryNurse />} />

                      <Route exact path="/serviceorderlist" element={<RequireAuth type="staff"><ServiceOrderList /></RequireAuth>} />
                      <Route exact path="/serviceorderdetails/:id" element={<RequireAuth type="staff"><ServiceOrderReqDetails /></RequireAuth>} />
                      <Route exact path="/notifications" element={<NotificationAll />} />
                      {/* <Route exact path="/notifications_general" element={<NotificationsGeneral />} /> */}
                      <Route exact path="/service-request" element={<ServiceRequests_doc/>} />
                    
                       <Route exact path="/search" element={<SearchResult setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>} />
                       <Route exact path="/search-nav" element={<SearchNavigation setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>} />
                       <Route exact path="/appointment" element={<Appointment setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>} />
                       <Route exact path="/my-orders" element={<MyOrders setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>} />
                       <Route exact path="/booking-details" element={<BookingDetails setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>} />
                     <Route exact path="/service-request-pharm" element={<PharmacyServiceFulfillment />} />
                    
                      <Route exact path="/service-request-nurse" element={<NurseServiceFulfillment />} />
                      <Route exact path="/payment" element={<Payment />} />
                      <Route exact path="/reports" element={<Reports />} />
                      {/* <Route exact path="/service-requestnurse" element={<PharmacyServiceFulfillment/>} />   */}

                      <Route exact path="/videocall" element={<VideoCall />} />
                      <Route exact path="/service-request-lab" element={<LabServiceFulfillment />} />

                      <Route exact path="/customercare" element={<ServiceCenter /> }/>
                    </Routes>
                  </div>
                 {showNavbarAndFooter &&  <Footer />}
                 
                </LoginProvider>
              </ProfileProvider>
            </HospLoginProvider>

          </LoginStatusProvider>
        </RegProvider>
</ConfirmationProvider>
      </BrowserRouter>


    </>
  );
}

export default App;
