import React,{useState,useEffect,useContext} from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./presc.css";
import "./presctable.css";
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { APIURL,TZ } from "../../Global";
import { MedContext } from '../contexts/MedContext';

import "./currentmedications.css";
import ImageScale from '../ImageScale/ImageScale';
import AddRemark from '../ServiceRequests/AddRemark';

function PastMedications(props) {
  const [infoData1, setInfoData1] = useState(""); 
    const [img ,setImg] = useState([]);
//const [medRender,setMedRender]=useContext(MedContext);
const [prescData ,setPrescData]= useState([]);
const [pastMed ,setPastMed]= useState(false)
const [icon ,setIcon]= useState(false)
const [view ,setView]= useState(false)

const [infoShow, setinfoShow] = useState(false);
  const [medList,setMedList]=useState([{


                 drug: "",
                 
                 from: "",
                 to:"",
                
                 dosage: "",

                 intake_count: [],
                 intake_time: '',
                 remarks: ""
                  }]);
           
  const [render,setRender]=useState(true);
  const [errorMsg,setErrorMsg]=useState('');
  const [submitMsg,setSubmitMsg]=useState("");
  const [modalShow,setModalShow] =useState(false);
  const [imageShow, setImageShow]= useState(false)

const [errorSubmitModalShow,setErrorSubmitModalShow]=useState(false);

 const id = props.id;

 const [keys,] = useState({'z' :false });


           useEffect(()=>{
            const handleKeyDown =(e)=>
            {      
              keys[e.key]=true
          
              if(e.altKey && keys['z']){
                setErrorSubmitModalShow(false)
                setErrorMsg('')
                setModalShow(false)
               // setMedRender(!medRender)
                setSubmitMsg('')
              }
          
            }
            window.addEventListener('keydown', handleKeyDown)
            
            
            const handleKeyUp =(ev)=>
          {
              
              keys[ev.key]= false
            }
          
          window.addEventListener('keyup', handleKeyUp)
          
          
          
            return()=>{
              window.removeEventListener('keydown', handleKeyDown)
              window.removeEventListener('keyup', handleKeyUp)
              
            }
          },[submitMsg, errorMsg]);


 
  useEffect(()=>{

    setMedList(medList)
   
  },[render, medList]);
  

  useEffect(()=>{
 

    const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
   const currentDate = new Date();
   const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
 
   
 // Get current timezone dynamically
 const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   
    
   
    axios.get(`${APIURL}/api/v1/doctor/update-current-medication/?medicines_list=past_medicines&patient_id=${id}&timeZone=${currentTimezone}&current_date=${formattedDate}`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){

       
        setPrescData(res.data.prescription)
       let data = []
        
       if(res.data.doctor.length ===0 && res.data.patient.length ===0 && res.data.prescription.length ===0){
        data=[]
      }

        if(res.data.doctor.length !==0){
            res.data.doctor.map((item)=> {
              data = data.concat(item.medicines)
            })
          }
        
          if(res.data.patient.length !==0){
            res.data.patient.map((item)=> {
              data = data.concat(item.medicine)
            })
          }

        if(res.data.prescription.length !==0){
          res.data.prescription.map((item)=> {
            data = data.concat(item.medicines)
          })
        }
        setMedList(data)


      }else{

        setErrorMsg(res.data.message? res.data.message : "Error in fetching data");
        setErrorSubmitModalShow(true);
      }


    })
    .catch(err=>{
        setErrorMsg("Error in fetching data");
        setErrorSubmitModalShow(true);
})
 

 
},[,id])
const AddPopup = (props) => {


  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="remark-width"
    >
        <div className="row" style={{width:"100%",marginTop:"2%",paddingLeft:"2%"}}><h6 className="col-11" style={{display:"flex"}}>Remarks- {infoData1.drug.charAt(0).toUpperCase() + (infoData1.drug).slice(1).toLowerCase()}</h6>
        <svg onClick={() => {setModalShow(false);}} className="col-1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"/></svg></div>
      
      <Modal.Body className="">
        <AddRemark    trackId={infoData1.id} from="pastmed"  />

      </Modal.Body>
     
    </Modal>
  );
}
const InfoPopup1 = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
      <span >{infoData1.drug.charAt(0).toUpperCase() + (infoData1.drug).slice(1).toLowerCase()}</span>
     
      </Modal.Header>
      <Modal.Body>
        <div>
        <div style={{ marginBottom: "1%" }} className="row">
           <div style={{ backgroundColor: "rgb(13, 197, 197)", color:"white", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Dosage form</div></div>
              <div style={{ backgroundColor: "rgb(13, 197, 197)",color:"white", border: "2px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>Intake time </div></div>
              <div style={{ backgroundColor: "rgb(13, 197, 197)",color:"white", border: "2px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>Route</div></div>
              <div style={{ backgroundColor: "rgb(13, 197, 197)",color:"white", border: "2px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>From </div></div>
              <div style={{ backgroundColor: "rgb(13, 197, 197)",color:"white", border: "2px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>To</div></div>
              </div>
            <div style={{ marginBottom: "1%" }} className="row">
             
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>{(infoData1.form).charAt(0).toUpperCase() + (infoData1.form).slice(1).toLowerCase().replace(/_/g, ' ')}</div></div>
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>{infoData1.intake_time}</div></div>
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>{infoData1.route&&infoData1.route.replace(/_/g, ' ')}</div></div>
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>{infoData1.from}</div></div>
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>{infoData1.to}</div></div>

            </div>

      
                    
        </div>
      </Modal.Body>
    </Modal>
  );
};
/*const AddRow=()=>{
  const list = medList;
  list.push({drug:"", strength: "",form:"",duration: "",route:"", dosage:"",frequency:"",intake_count:[]});
  
  setMedList(list);
  setRender(!render);
  
}*/




/*const handleNewRow=(e)=>{
  e.preventDefault();
     const newMed = medList;
    newMed.push({drug:""});
    setMedList(newMed);

}*/

const handleShow=(index)=> {
  const newarr=medList;
  const newList = newarr.filter((elt,i)=>{
    return i=== index
  })
let data=[]
  for(let i=0; i<prescData.length; i++) {
    if(prescData[i].prescription_id===newList[0].prescription_id){
        prescData[i].image.map(item=>{
            data= data.concat(item)
        })
      setImg(data)
      break;
    }
    else {
      setImg([])
    }
  }

  
  setImageShow(true)
  
  
}
const renderEditable=(cellInfo)=>{

  return(
    <div 
        className=""
      > {cellInfo.column.id==="form"||cellInfo.column.id==="route"?(medList[cellInfo.index][cellInfo.column.id]&&medList[cellInfo.index][cellInfo.column.id]!==""&&(medList[cellInfo.index][cellInfo.column.id]).replace(/_/g, ' ').replace(/-/g, ' ')):medList[cellInfo.index][cellInfo.column.id]}</div> )
}

const renderDrug=(cellInfo)=>{

  return(<div style={{display:"flex"}}>
   <div 
        className=""
      > {(medList[cellInfo.index][cellInfo.column.id]).charAt(0).toUpperCase() + (medList[cellInfo.index][cellInfo.column.id]).slice(1).toLowerCase()} </div></div> )
}
const renderEditableCount=(cellInfo)=>{

  return(
    <div 
        className=""
      > {medList[cellInfo.index].intake_count}{medList[cellInfo.index].intake_unit==="ml"?"mL":medList[cellInfo.index].intake_unit}</div> )
}

const renderDr=(cellInfo)=>{
const creator =medList[cellInfo.index]
const type =creator.creator_type
  return(

    type==='doctor'?

    <div style={{ backgroundColor: "#fafafa" }}
        className=""
      ><h6> {medList[cellInfo.index].doctor}</h6>
       <h6> {medList[cellInfo.index].hospital} </h6> </div> :

       type==='self'?

       <div style={{ backgroundColor: "#fafafa" }}
        className=""
      ><h6>Added by Patient</h6>
       </div> : 

       type=== undefined ?
       <div style={{ backgroundColor: "#fafafa" }}
        className=""
      ><h6>Added as a Prescription</h6>
      <button className="presc-btn" 
       onClick={()=>{handleShow(cellInfo.index);}}>Click to View</button>
       </div> : null

        )
}
const renderIntakeTime=(cellInfo)=>{
  const food_time=medList[cellInfo.index][cellInfo.column.id];
  let food_label="";
  if(food_time=== "0"){
    food_label= "N/A";
  }
  else if(food_time=== "1"){
    food_label= "Before food";
  }
  else if(food_time=== "2"){
    food_label= "After food";
  } 
   return(

    <div style={{ backgroundColor: "#fafafa" }}
        className=""
      > {food_label}</div> 
    

)
}
const renderEditableFreq = (cellInfo) => {


  return (
    <div style={{ textTransform: "lowercase" }}

    > {medList[cellInfo.index][cellInfo.column.id]!==null?medList[cellInfo.index][cellInfo.column.id]:(medList[cellInfo.index].date+" "+medList[cellInfo.index].time)}</div>)
}
const renderEditableQty=(cellInfo)=>{


  return(
    <div style={{textTransform:"lowercase"}}
        
      > {medList[cellInfo.index][cellInfo.column.id]}{" "}{medList[cellInfo.index].intake_unit}</div> )
}
const renderCurrentEdit=(cellInfo)=>{
  const current=medList[cellInfo.index][cellInfo.column.id];
  let curr=""
  if(current===false){
    curr="No"
  }
  else if(current===true){
    curr="Yes"
  }
  return(
    <div style={{ backgroundColor: "#fafafa" }}
    className=""
    > {curr}</div> 
  )
}
const renderATC=(cellInfo)=>{


  return(
    <div 
        className=""
      > {medList[cellInfo.index][cellInfo.column.id]}</div> )
}
const renderEditableDuration=(cellInfo)=>{
let day = 'day';
if (medList[cellInfo.index][cellInfo.column.id] && parseInt(medList[cellInfo.index][cellInfo.column.id]) > 1) {
  day = 'days'
}
  return(
    !medList[cellInfo.index].is_emergency&& <div style={{}}
      
      > {medList[cellInfo.index][cellInfo.column.id]} {medList[cellInfo.index].duration_unit} </div> )
}
const renderEditableCr=(cellInfo)=>{


  return(
    <div  style={{textTransform:"capitalize"}}
        className=""
      > {medList[cellInfo.index][cellInfo.column.id]}
      <br/><small>{medList[cellInfo.index].creator_type}</small></div> )
}
const columns = [
  
{
  Header: "Drug Name",
  accessor: "drug",
  Cell: renderDrug,
 
  
  minWidth:200,
   sortable:false,
   filterable:false,
   width:250

},
{
  Header: "ATC Code",
  accessor: "med_code",
  minWidth:80,
  width:110,
  Cell: renderATC,
  sortable:false,
  filterable:false

},
{
  // Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
  //              data-placement = "top" title = "INTAKE COUNT">COURSE</span>,
  Header: "Intake Period",
  accessor: "intake",
  Cell: renderEditable,
  sortable:false,
  // width:100,
  // minWidth:100,
  filterable:false

},

{
  Header:"Quantity",
  accessor: "intake_count",
  // minWidth:100,
  // width:100,
  Cell: renderEditableQty,
  sortable:false,
  filterable:false

},
// {
//   Header:"Unit",
//   accessor: "intake_unit",
//   minWidth:100,
//   width:80,
//   Cell: renderIntakeUnit,
//   sortable:false,
//   filterable:false

// },

// {
  // Header: "Intake Time",
  // accessor: "intake_time",
  // minWidth:120,
  // width:150,
  // Cell: renderIntakeCount,
  // sortable:false,
  // filterable:false

// },
// {
//   Header: "From",
//   accessor: "from",
 
 
//   Cell: renderEditable,
//   sortable:false,
//   filterable:false

// },
// {
//   Header: "TO",
//   accessor: "to",
 

//   Cell: renderEditable,
//   sortable:false,
//   filterable:false

// },
{
  Header: "Frequency",
  accessor: "frequency",
  // minWidth:140,
  // width:130,
  Cell: renderEditableFreq,
  sortable:false,
  filterable:false

},

{
  Header: "Duration",
  accessor: "duration",
  // minWidth:80,
  // width:107,
  Cell: renderEditableDuration,
  sortable:false,
  filterable:false

},
{
  Header: "Prescribed by",
  accessor: "creator",
  Cell: renderEditableCr,
 
  
  // minWidth:100,
   sortable:false,
   filterable:false,
   width:180

},
{
  Header: "",
  accessor: "",
  minWidth:70,
  width:70,
  Cell: props=>{
    return (<div >
  
  <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">More info</Tooltip>}>
  <svg onClick={()=>{setInfoData1(medList[props.index]);setinfoShow(true) }} style={{cursor:"pointer"}}xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 256 256"><path fill="#FF9C01" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m-4 48a12 12 0 1 1-12 12a12 12 0 0 1 12-12m12 112a16 16 0 0 1-16-16v-40a8 8 0 0 1 0-16a16 16 0 0 1 16 16v40a8 8 0 0 1 0 16"/></svg>
  </OverlayTrigger>
  &nbsp;&nbsp;&nbsp;
    <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Remarks</Tooltip>}>
      
      
         <svg onClick={() =>{setInfoData1(medList[props.index]); setModalShow(true);}} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path d="M20 2H10a2 2 0 0 0-2 2v2h8a2 2 0 0 1 2 2v8h2a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="rgb(42, 99, 121)"/><path d="M4 22h10c1.103 0 2-.897 2-2V10c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2zm2-10h6v2H6v-2zm0 4h6v2H6v-2z" fill="rgb(42, 99, 121)"/></svg>
     </OverlayTrigger>
  </div>
   
)
  },
  sortable:false,
  filterable:false

},

]


const ErrorSubmitPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}




const SubmitPopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{submitMsg}!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button> 
       
      
      </Modal.Footer>
    </Modal>
  );
}

const ImagePopUp=(props)=> {
  return (
    <Modal
      {...props}
      dialogClassName="modal-80w"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
              <ImageScale img={img} presc={true} />
          
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button> 
       
      
      </Modal.Footer>
    </Modal>
  );
}

  return(
    <div>
    
          
          {/* <button style={{marginLeft:'70vw'}} onClick={()=>{setPastMed(!pastMed); setIcon(!icon); setView(!view)}} className="btn btn-primary btn-col  btn-square"> { view? <span>Hide</span> : <span>View</span>} Past Medications { icon ? <i className="fas fa-arrow-circle-up"></i>: <i className="fas fa-arrow-circle-down"></i>} </button> */}
        
    {/* {
      pastMed ? */}
      
    <div className="overlay-body">

    <div className="add-presc-page">
   
    

<div className="current-table-holder" style={{ marginTop: "1%" }}>
    <ReactTable 
         columns={columns}
         data={medList}
         defaultPageSize = {10}
         filterable
         resizable
         noDataText={"No data"}
         className="observation-table"
         >

         </ReactTable>
         <br/>
  </div>
       
          
        
        
      </div>
      <div className="divider"></div>
     







  {errorMsg !=="" ?
    <ErrorSubmitPopup
        show={errorSubmitModalShow}
        onHide={() =>  {setErrorSubmitModalShow(false);
                        setErrorMsg('')}}
        
      />   : null } 

{
        modalShow ?
          <AddPopup
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
          /> : null
      }

 {submitMsg!=="" ? 

          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {setModalShow(false);
                        // setMedRender(!medRender);
                        setSubmitMsg('')}}
      /> 
      : ""}
 {infoShow ? (
                <InfoPopup1
                    show={infoShow}
                    onHide={() => {
                        setinfoShow(false);
                    }}
                />
            ) : (
                ""
            )}
{imageShow ? 

<ImagePopUp
show={imageShow}
image={img}
onHide={() =>  {setImageShow(false)}}
/> 
: null}
  
</div> 


</div>
)
}

export default PastMedications
