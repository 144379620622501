import React, { useEffect, useState, useRef } from "react";
import { Chart } from 'chart.js';
// import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import axios from "axios";
import DatePicker from "react-date-picker";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { APIURL, TZ } from "../../Global";
import "./pulse.css";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Carousel from "react-grid-carousel";
import AddPulse from "../vitals/AddPulse"
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { CategoryScale } from 'chart.js';
import { useAlert } from "react-alert";
Chart.register(CategoryScale);
let myChart;
function VitalsSp(props) {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
  const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [patientId] = useState(props.id);
  const alert = useAlert();
  const [inference, setInference] = useState([]);
  const [spArray, setSpArray] = useState([]);
  const [spDatesArray, setSpDatesArray] = useState([]);
  const [spList, setSpList] = useState([]);
  const [updateRender, setUpdateRender] = useState(false);
  const [unit, setUnit] = useState();
  const [load, setLoad] = useState("");
  const [inferenceLoad, setInferenceLoad] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [deleteIndex, setDeleteIndex] = useState('');
  const [deleteShow, setDeleteShow] = useState(false)
  const [submitMsg, setSubmitMsg] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [glucoDeleteRowValue, setGlucoDeleteRowValue] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const delConfirmRef = useRef(null)
  useEffect(() => {
    const handleKey = (e) => {

      if (e.altKey && e.key === 's') {
        e.preventDefault()
        delConfirmRef.current.click()
      } else if (e.key === 'Enter') {
        e.preventDefault()
        delConfirmRef.current.click()
      }

    }
    document.addEventListener("keydown", handleKey);

    return () => {
      document.removeEventListener("keydown", handleKey);
    }

  }, []);

  useEffect(() => {

    const ctx = document.getElementById("chart-sp").getContext("2d");

    if (myChart) {
      myChart.destroy();
    }
    myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: spDatesArray,
        datasets: [
          {
            label: "SPO2",
            data: spArray,
            backgroundColor: "purple",

            pointBorderWidth: 2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: "purple",
          },
        ],
        tooltips: {
          backgroundColor: "purple",
        },
      },

      options: {
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: `Spo2 Values  ${unit ? "(" + unit + ")" : null} `,
              },

              ticks: {
                beginAtZero: true,
                stepSize: 10,
                padding: 20,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Date",
              },
            },
          ],
        },
        legend: {
          position: "right",
          labels: {
            fontColor: "#000",
          },
        },
      },
    });
  }, [updateRender, spArray, spDatesArray, unit]);


  useEffect(() => {

    handleGraph();
    handleTableData();

  }, [updateRender]);

  const submitPulseHandle = () => {
    handleGraph();
    handleTableData();
  };

  const handleGraph = () => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    setLoad("Loading Data...");

    axios
      .get(
        `${APIURL}/api/v1/doctor/patient-spo2/?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}&timeZone=${timezoneOffset}&sort_by=asc`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setLoad("");
          const arr = res.data.results;
          const spo2 = [];

          arr.map((result) => {
            spo2.push(result.spo2);
          });
          setSpArray(spo2);

          let newArrayDates = [];
          arr.map((result) => {
            newArrayDates.push(result.date.slice(0, 10));
          });
          setSpDatesArray(newArrayDates);

          setSpList(res.data.results);
          let unit1 = null;
          if (res.data.results.length > 0) {
            unit1 = res.data.results[0].unit;
          } else {
            unit1 = null;
          }

          setUnit(unit1);
        } else {
          setLoad("Error in Fetching Data");
        }
      })
      .catch((err) => {
        setLoad("Error in Fetching Data");
      });
  }

  const handleTableData = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    setInferenceLoad("Loading Data...");

    axios
      .get(
        `${APIURL}/api/v1/patient/spo2-inferences/?patient_id=${patientId}&date_from=${startDate}&date_to=${endDate}&timeZone=${timezoneOffset}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setInferenceLoad("");
          setInference(res.data.data);
        } else {
          setInferenceLoad("");
        }
      })
      .catch((err) => {
        setInferenceLoad("no data");
      });
  }


  const callDelete = () => {

    ;
    setDeleteShow(false)
    let idToDelete = glucoDeleteRowValue;
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');
    const headers = {

      "Authorization": 'Token ' + str,
      'Content-Type': 'application/json'
    }
    const data =
    {
      patient_id: "1780"
    }

    axios.delete(`${APIURL}/api/v1/patient/tests/${idToDelete}`, {
      data: { patient_id: patientId },
      headers: { Authorization: "Token " + str },
    })



      .then(res => {
        ;
        if (res.status == 200) {



          alert.success("Deleted Successfully")

          handleTableData();
          handleGraph();
          setDeleteShow(false)

        }
        else {
          alert.error(res.data.message)

        }


        setDeleteShow(false);


      })
      .catch(err => {
        ;
        setErrorMsg('in Data Submission. Please try again!')
        setErrorSubmit(true)
      })

  }

  const SubmitPopUp = ({ show, onHide }) => {


    return (
      <Modal
        show
        onHide
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{submitMsg}</h4>

        </Modal.Body>
        <Modal.Footer>


          <Button onClick={onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }


  // const deleteRow=(index)=>{


  //   let b=pulseList[index.index]

  //   callDelete(b.test_id);

  // }

  const DeleteConfirmPopup = (props) => {


    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div><div className="alert-border"><span className="alert-icon">!</span></div></div>
          <label style={{ margin: '20px auto', fontSize: '18px', color: '#4e4e4e' }}> Are you sure you want to delete? </label>
          <div className="form-group" style={{ display: 'flex', margin: 'auto' }}>
            <button type="button" className='swirl-butns' style={{ margin: '10px 4px 0px 0px', backgroundColor: '#dc3545' }} onClick={props.onHide}> Cancel </button>
            <button type="submit" className='swirl-butns' style={{ margin: '10px 0px 0px 4px', backgroundColor: '#2b69a3' }} ref={delConfirmRef} onClick={callDelete} >Confirm </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const handleGo = (e) => {
    e.preventDefault();
    setUpdateRender(!updateRender);
  };

  const handleFromDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setStartDate(DateExtracted);
    }
  };

  const handleToDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setEndDate(DateExtracted);
    }
  };

  const renderPulse = (cellInfo) => {
    // const data = pulseList;
    // const pulseData = [...data];
    const date4 = spList;
    const date5 = [...date4].reverse();

    return (
      <h6>
        {" "}
        {date5[cellInfo.index][cellInfo.column.id]}%
        {" "}
      </h6>
    );
  };
  const renderDate = (cellInfo) => {
    const date4 = spList;
    const date5 = [...date4].reverse();

    return <h6> {date5[cellInfo.index].date.slice(0, 16)} </h6>;
  };

  const renderFitData = (cellInfo) => {
    const data = spList;
    const fitData = [...data];

    return (
      <h6> {fitData[cellInfo.index][cellInfo.column.id] ? "Yes" : "No"} </h6>
    );
  };
  const handleDeleteClick = (cellInfo) => {
    const date4 = spList;
    const date5 = [...date4].reverse();

    let testID = date5[cellInfo.index][cellInfo.column.id];

    setDeleteShow(true)
    setGlucoDeleteRowValue(testID)

    //setDeleteIndex(props)
  }

  const columnsSp = [
    {
      Header: "Date and Time",
      accessor: "date",
      Cell: renderDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      // width: 170,
      // minWidth: 50,
    },
    {
      Header: "Spo2(%)",
      accessor: "spo2",
      Cell: renderPulse,
      // width: 140,
      // minWidth: 50,
    },
    {

      accessor: "test_id",
      //Cell: renderFitData,
      show: false,
    },
    // {
    //   Header: "Smart Device Data",
    //   accessor: "is_wearables",
    //   Cell: renderFitData,
    //   width: 150,
    //   minWidth: 50,
    // },
    {
      Header: <span className="table-header-tooltip" data-toggle="tooltip"
        data-placement="top" title="Delete Row"><i className="fas fa-trash-alt"></i></span>,
      Cell: props => {
        return (
          <i className="fas fa-times close-button" onClick={() => handleDeleteClick(props)}     ></i>
        )
      },
      accessor: "test_id",
      sortable: false,
      filterable: false,
      // minWidth:50,
      // width:50

    }
  ];
  const Sp_Table = (
    <ReactTable
      columns={columnsSp}
      data={spList}
      defaultPageSize={10}
      resizable
    ></ReactTable>
  );

  const inferenceData =
    inferenceLoad === "" ? (
      inference !== undefined ? (
        <div className="inference-container analysis-subsection" style={{ height: "60%" }}>
          <div className="inference-container-header box-theme">
            <h6 className="inference-container-title"> Analysis</h6>
          </div>
          <div className="inference-container-body" style={{ marginLeft: "2%", marginTop: "2%" }}>
            {inference.is_consistant !== null ?

              <><div><h6 className="title-of-lists col-6">Consistency :</h6>
                <h6 style={{ textTransform: "capitalize" }} className="text-success">{inference.is_consistant}</h6></div>
                <div><h6 className="title-of-lists col-6">Trend :</h6>
                  <h6 style={{ textTransform: "capitalize" }} className="text-success">{inference.trend}</h6></div>
                <div><h6 className="title-of-lists col-6">Status :</h6>
                  <h6 style={{ textTransform: "capitalize" }} className="text-success">{inference.keyword}</h6></div></>

              : <div className="text-success">This patient's SPO2 status is &nbsp;<b>{inference.keyword}.</b></div>}

            {/* <h6 className="text-success font-weight-bold">
             SPO2 level status :{" "}
            </h6>{" "}
            <p>
             <b> {inference.keyword}</b>
            </p>
            <br />
            <h6 className="text-success font-weight-bold">
              Avareage SPO2  :{" "}
            </h6>{" "}
            <p>
             <b> {inference.mean}</b>
            </p> */}
            {/* <br />
            <h6 className="text-muted font-weight-bold">
              Pulse Deviation :{" "}
            </h6>{" "}
            <p>{inference.pulse_sd}</p> */}
          </div>
        </div>
      ) : (
        <h6 style={{ color: "red" }}> No data to show! </h6>
      )
    ) : inferenceLoad === "Error in Fetching Data" ? (
      <h6 style={{ color: "red" }}>{inferenceLoad}</h6>
    ) : (
      <h6 style={{ color: "grey" }}>{inferenceLoad}</h6>
    );
  return (
    <>
      <div className="food-habits-page">
        <h5 className="title-of-tasks">SPO2 Details </h5>
        <div className="food-habits-header">

          {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}

          <div className="calender-pickers-from-to appFormDate" style={{ display: "flex", borderRadius: "2%", borderColor: "black", marginLeft: "2%" }}>
            <div className="span-date">
              <span style={{ marginTop: "1%" }}><b> From </b></span>

              <DatePicker
                value={new Date(startDate)}
                onChange={(date) => {
                  handleFromDate(date);
                }}
                maxDate={new Date()}
              />
            </div>
            <div className="span-date">
              <span style={{ marginTop: "1%" }}><b> To </b></span>

              <DatePicker
                value={new Date(endDate)}
                onChange={(date) => {
                  handleToDate(date);
                }}
                maxDate={new Date()}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={handleGo}
            >
              {" "}
              Check{" "}
            </button>
          </div>
          <br />
          <br />
        </div>
        <div className="food-habits-sections chol-section">
          <div className="row">

            <div className="chart-div-glucose col-6" style={{ borderRadius: "2px", borderColor: "black", marginLeft: "3%" }}>
              <h5 className="title-of-lists"> Graph </h5>
              <canvas id="chart-sp" width="800" height="400"></canvas>
            </div>
            <div className="food-table col-4">
              <h5 className=" title-of-lists"> Inference </h5>

              {inferenceData}
            </div>
          </div>

          <div className="food-table title-of-page" style={{ width: "600px", marginLeft: "25%" }}>
            <h5 className=" title-of-lists"> Table </h5>
            {load === "" ? (
              spList.length > 0 ? (
                <>{Sp_Table}</>
              ) : (
                <h6 style={{ color: "red" }}> No data to show! </h6>
              )
            ) : load === "Error in Fetching Data" ? (
              <h6 style={{ color: "red" }}>{load}</h6>
            ) : (
              <h6 style={{ color: "grey" }}>{load}</h6>
            )}
          </div>


        </div>
      </div>
      {submitMsg !== "" && submitMsg !== null ?
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            handleTableData();
            handleGraph();
            setDeleteShow(false); setModalShow(false);
            setSubmitMsg('');
          }}


        /> : null}

      <DeleteConfirmPopup
        show={deleteShow}
        onHide={() => {
          setDeleteShow(false);
          setModalShow(false)
        }}

      />



    </>
  );
}
// function AddPulseHeader({ children, eventKey }) {
//   const [toggle, setToggle ] = useState(false);
//   const decoratedOnClick = Accordion(eventKey, () => setToggle(!toggle));

//   return (
//     <h5 className="addH5" onClick={decoratedOnClick}>
//       Add Pulse +<svg className={toggle ? "opened" : "closed"} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//         <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
//       </svg>
//     </h5>
//   );
// }
export default VitalsSp;
