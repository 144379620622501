import React, { useState } from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from "react-alert";
function EditMedicine(props) {
  const alert = useAlert();
  const [completeShow, setCompleteShow] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState(props.data)
  const value = props.data.dosage_form ? props.data.dosage_form: '';
  const label = props.data.dosage_form ? props.data.dosage_form : '';
  const [dosage_type, Setdosage_type] = useState({ value: value, label: label.replace(/_/g, ' ').toUpperCase() });
  const value2 = props.data.med_type ? props.data.med_type: '';
  const label2 = props.data.med_type ? props.data.med_type : '';
  const [med_type, setMedicinetype] = useState({ value: value2, label: label2.replace(/_/g, ' ').toUpperCase() });
  const value3 = props.data.strength_unit ? props.data.strength_unit : '';
  const label3 = props.data.strength_unit ? props.data.strength_unit : '';
  const [strengthunit, setStrengthUnit] = useState({ value: value3, label: label3 });
  const value1 = props.data.route ? props.data.route: '';
  const label1 = props.data.route ? props.data.route : '';
  const [routine, setRoutine] = useState({ value: value1, label: label1.replace(/_/g, ' ').replace(/-/g, ' ').toUpperCase() })
  const [errormsg,setErrorMsg]=useState("")

  //  const [dosage_type, Setdosage_type]= useState({value:props.data.DosageOptions, label: props.data.DosageOptions})
  //  const [routine, setRoutine]= useState({value:props.data.RouteOptions, label: props.data.RouteOptions}


  const [successShow, setSuccessShow] = useState(false)
  const [errorShow, setErrorShow] = useState(false)

  const [error, setError] = useState({
    medicinename: '',
    genericname: '',
    strength: '',
    medicinetype: '',
    dosage_type: '',
    medicinecode: '',
    routine: '',
    strengthunit: '',
    composition: ''

  });

  const DosageOptions = [
    { value: 'chewable_tablet', label: 'CHEWABLE TABLET'},
{ value: 'film_coated_tablet', label: 'FILM COATED TABLET'},
{ value: 'oral_suspension', label: 'ORAL SUSPENSION'},
{ value: 'solution_for_injection_in_a_pre_filled_syringe', label: 'SOLUTION FOR INJECTION IN A PRE FILLED SYRINGE'},
{ value: 'solution_for_injection', label: 'SOLUTION FOR INJECTION'},
{ value: 'hard_capsule', label: 'HARD CAPSULE'},
{ value: 'tablet', label: 'TABLET'},
{ value: 'modified_release_tablet', label: 'MODIFIED RELEASE TABLET'},
{ value: 'gastro_resistant_capsule', label: 'GASTRO RESISTANT CAPSULE'},
{ value: 'prolonged_release_tablet', label: 'PROLONGED RELEASE TABLET'},
{ value: 'coated_tablet', label: 'COATED TABLET'},
{ value: 'gastro_resistant_tablet', label: 'GASTRO RESISTANT TABLET'},
{ value: 'soft_capsule', label: 'SOFT CAPSULE'},
{ value: 'concentrate_for_solution_for_infusion_or_injection', label: 'CONCENTRATE FOR SOLUTION FOR INFUSION OR INJECTION'},
{ value: 'powder_and_solvent_for_solution_for_infusion_or_injection', label: 'POWDER AND SOLVENT FOR SOLUTION FOR INFUSION OR INJECTION'},
{ value: 'concentrate_for_solution_for_infusion', label: 'CONCENTRATE FOR SOLUTION FOR INFUSION'},
{ value: 'solution_for_infusion', label: 'SOLUTION FOR INFUSION'},
{ value: 'capsule', label: 'CAPSULE'},
{ value: 'powder_and_solvent_for_solution_for_infusion', label: 'POWDER AND SOLVENT FOR SOLUTION FOR INFUSION'},
{ value: 'injection', label: 'INJECTION'},
{ value: 'ointment', label: 'OINTMENT'},
{ value: 'suppository', label: 'SUPPOSITORY'},
{ value: 'rectal_cream', label: 'RECTAL CREAM'},
{ value: 'cream', label: 'CREAM'},
{ value: 'cutaneous_stick', label: 'CUTANEOUS STICK'},
{ value: 'vaginal_suppositories', label: 'VAGINAL SUPPOSITORIES'},
{ value: 'suspension_for_injection', label: 'SUSPENSION FOR INJECTION'},
{ value: 'powder_for_oral_suspension', label: 'POWDER FOR ORAL SUSPENSION'},
{ value: 'powder_for_solution_for_injectioninfusion', label: 'POWDER FOR SOLUTION FOR INJECTION/INFUSION'},
{ value: 'granules_for_oral_suspension', label: 'GRANULES FOR ORAL SUSPENSION'},
{ value: 'powder_for_solution_for_infusion_or_injection', label: 'POWDER FOR SOLUTION FOR INFUSION OR INJECTION'},
{ value: 'solution_for_injection_infusion', label: 'SOLUTION FOR INJECTION/INFUSION'},
{ value: 'powder_for_solution_for_injection', label: 'POWDER FOR SOLUTION FOR INJECTION'},
{ value: 'powder_for_solution_for_infusion', label: 'POWDER FOR SOLUTION FOR INFUSION'},
{ value: 'powder_for_concentrate_for_dispersion_for_infusion', label: 'POWDER FOR CONCENTRATE FOR DISPERSION FOR INFUSION'},
{ value: 'suspension_for_injection_in_pre_filled_syringe', label: 'SUSPENSION FOR INJECTION IN PRE FILLED SYRINGE'},
{ value: 'powder_for_suspension_for_injection', label: 'POWDER FOR SUSPENSION FOR INJECTION'},
{ value: 'concentrate_and_solvent_for_solution_for_infusion', label: 'CONCENTRATE AND SOLVENT FOR SOLUTION FOR INFUSION'},
{ value: 'powder_and_solvent_for_solution_for_injection', label: 'POWDER AND SOLVENT FOR SOLUTION FOR INJECTION'},
{ value: 'solution_for_infusion_or_injection', label: 'SOLUTION FOR INFUSION OR INJECTION'},
{ value: 'solution', label: 'SOLUTION'},
{ value: 'effervescent_tablet', label: 'EFFERVESCENT TABLET'},
{ value: 'ear_drops_solution', label: 'EAR DROPS, SOLUTION'},
{ value: 'syrup', label: 'SYRUP'},
{ value: 'effervescent_granules', label: 'EFFERVESCENT GRANULES'},
{ value: 'granules', label: 'GRANULES'},
{ value: 'solution_for_injection_in_a_pre_filled_pen', label: 'SOLUTION FOR INJECTION IN A PRE FILLED PEN'},
{ value: 'oral_solution', label: 'ORAL SOLUTION'},
{ value: 'prolonged_release_hard_capsule', label: 'PROLONGED RELEASE HARD CAPSULE'},
{ value: 'nasal_spray_solution', label: 'NASAL SPRAY, SOLUTION'},
{ value: 'nasal_spray_suspension', label: 'NASAL SPRAY, SUSPENSION'},
{ value: 'oromucosal_spray', label: 'OROMUCOSAL SPRAY'},
{ value: 'inhalation_powder', label: 'INHALATION POWDER'},
{ value: 'inhalation_powder_pre_dispensed', label: 'INHALATION POWDER, PRE DISPENSED'},
{ value: 'pressurised_inhalation_solution', label: 'PRESSURISED INHALATION, SOLUTION'},
{ value: 'nebuliser_solution', label: 'NEBULISER SOLUTION'},
{ value: 'eye_ointment', label: 'EYE OINTMENT'},
{ value: 'eye_drops_solution', label: 'EYE DROPS, SOLUTION'},
{ value: 'medicated_chewing_gum', label: 'MEDICATED CHEWING GUM'},
{ value: 'rectal_gel', label: 'RECTAL GEL'},
{ value: 'powder_for_oral_solution', label: 'POWDER FOR ORAL SOLUTION'},
{ value: 'solution_for_haemodialysis_solution_for_haemofiltration', label: 'SOLUTION FOR HAEMODIALYSIS, SOLUTION FOR HAEMOFILTRATION'},
{ value: 'cutaneous_emulsion', label: 'CUTANEOUS EMULSION'},
{ value: 'nasal_ointment', label: 'NASAL OINTMENT'},
{ value: 'cutaneous_solution', label: 'CUTANEOUS SOLUTION'},
{ value: 'cutaneous_spray_powder', label: 'CUTANEOUS SPRAY, POWDER'},
{ value: 'oromucosal_solution', label: 'OROMUCOSAL SOLUTION'},
{ value: 'vaginal_solution', label: 'VAGINAL SOLUTION'},
{ value: 'powder_and_solvent_for_suspension_for_injection', label: 'POWDER AND SOLVENT FOR SUSPENSION FOR INJECTION'},
{ value: 'powder_for_concentrate_for_solution_for_infusion', label: 'POWDER FOR CONCENTRATE FOR SOLUTION FOR INFUSION'},
{ value: 'oral_liquid', label: 'ORAL LIQUID'},
{ value: 'oromucosal_gel', label: 'OROMUCOSAL GEL'},
{ value: 'sublingual_tablet', label: 'SUBLINGUAL TABLET'},
{ value: 'nasal_drops_solution', label: 'NASAL DROPS, SOLUTION'},
{ value: 'nasal_spray', label: 'NASAL SPRAY'},
{ value: 'pressurised_inhalation_suspension', label: 'PRESSURISED INHALATION, SUSPENSION'},
{ value: 'nebuliser_suspension', label: 'NEBULISER SUSPENSION'},
{ value: 'inhalation_vapour', label: 'INHALATION VAPOUR'},
{ value: 'prolonged_release_capsule', label: 'PROLONGED RELEASE CAPSULE'},
{ value: 'eye_drops_suspension', label: 'EYE DROPS, SUSPENSION'},
{ value: 'endotracheopulmonary_instillation_suspension', label: 'ENDOTRACHEOPULMONARY INSTILLATION, SUSPENSION'},
{ value: 'gel', label: 'GEL'},
{ value: 'modified_release_hard_capsule', label: 'MODIFIED RELEASE HARD CAPSULE'},
{ value: 'rectal_solution', label: 'RECTAL SOLUTION'},
{ value: 'rectal_foam', label: 'RECTAL FOAM'},
{ value: 'gastro_resistant_hard_capsule', label: 'GASTRO RESISTANT HARD CAPSULE'},
{ value: 'granules_for_oral_solution', label: 'GRANULES FOR ORAL SOLUTION'},
{ value: 'solution_for_injection_in_pre_filled_syringe', label: 'SOLUTION FOR INJECTION IN PRE FILLED SYRINGE'},
{ value: 'spray_(solution)', label: 'SPRAY (SOLUTION)'},
{ value: 'cutaneous_powder', label: 'CUTANEOUS POWDER'},
{ value: 'irrigation_solution', label: 'IRRIGATION SOLUTION'},
{ value: 'impregnated_dressing', label: 'IMPREGNATED DRESSING'},
{ value: 'vaginal_cream_vaginal_tablet', label: 'VAGINAL CREAM, VAGINAL TABLET'},
{ value: 'pessary', label: 'PESSARY'},
{ value: 'vaginal_cream', label: 'VAGINAL CREAM'},
{ value: 'vaginal_tablet', label: 'VAGINAL TABLET'},
{ value: 'pessary_vaginal_cream', label: 'PESSARY, VAGINAL CREAM'},
{ value: 'granules_suspension_for_injection', label: 'GRANULES, SUSPENSION FOR INJECTION'},
{ value: 'powder_and_solvent_for_concentrate_for_solution_for_infusion', label: 'POWDER AND SOLVENT FOR CONCENTRATE FOR SOLUTION FOR INFUSION'},
{ value: 'orodispersible_tablet', label: 'ORODISPERSIBLE TABLET'},
{ value: 'oral_drops_solution', label: 'ORAL DROPS, SOLUTION'},
{ value: 'lozenge', label: 'LOZENGE'},
{ value: 'ear_drops_suspension', label: 'EAR DROPS, SUSPENSION'},
{ value: 'powder_for_oral_suspension_powder_for_rectal_suspension', label: 'POWDER FOR ORAL SUSPENSION, POWDER FOR RECTAL SUSPENSION'},
{ value: 'oral_gel', label: 'ORAL GEL'},
{ value: 'solution_for_peritoneal_dialysis', label: 'SOLUTION FOR PERITONEAL DIALYSIS'},
{ value: 'cutaneous_liquid', label: 'CUTANEOUS LIQUID'},
{ value: 'concentrate_for_cutaneous_solution', label: 'CONCENTRATE FOR CUTANEOUS SOLUTION'},
{ value: 'oral_lyophilisate', label: 'ORAL LYOPHILISATE'},
{ value: 'powder_for_injection', label: 'POWDER FOR INJECTION'},
{ value: 'gargle_mouth_wash', label: 'GARGLE, MOUTH WASH'},
{ value: 'transdermal_patch', label: 'TRANSDERMAL PATCH'},
{ value: 'oral_solution_syrup', label: 'ORAL SOLUTION, SYRUP'},
{ value: 'eye_drops', label: 'EYE DROPS'},
{ value: 'coated_tablet_gastro_resistant_tablet', label: 'COATED TABLET, GASTRO RESISTANT TABLET'},
{ value: 'enema', label: 'ENEMA'},
{ value: 'dispersible_tablet', label: 'DISPERSIBLE TABLET'},
{ value: 'oral_powder', label: 'ORAL POWDER'},
{ value: 'cutaneous_foam', label: 'CUTANEOUS FOAM'},
{ value: 'shampoo', label: 'SHAMPOO'},
{ value: 'inhalation_gas', label: 'INHALATION GAS'},
{ value: 'concentrate_for_solution_for_infusion_solution_for_injection', label: 'CONCENTRATE FOR SOLUTION FOR INFUSION, SOLUTION FOR INJECTION'},
{ value: 'cutaneous_spray_solution', label: 'CUTANEOUS SPRAY, SOLUTION'},
{ value: 'nasal_drops_suspension', label: 'NASAL DROPS, SUSPENSION'},
{ value: 'controlled_release_tablets', label: 'CONTROLLED RELEASE TABLETS'},
{ value: 'intravenous_infusion', label: 'INTRAVENOUS INFUSION'},
{ value: 'implant', label: 'IMPLANT'},
{ value: 'ear_drops_eye_drops', label: 'EAR DROPS, EYE DROPS'},
{ value: 'gastroenteral_solution', label: 'GASTROENTERAL SOLUTION'},
{ value: 'gastro_resistant_granules', label: 'GASTRO RESISTANT GRANULES'},
{ value: 'emulsion_for_infusion', label: 'EMULSION FOR INFUSION'},
{ value: 'inhalation_vapour_liquid', label: 'INHALATION VAPOUR, LIQUID'},
{ value: 'kit_for_radiopharmaceutical_preparation', label: 'KIT FOR RADIOPHARMACEUTICAL PREPARATION'},
{ value: 'intrauterine_delivery_system', label: 'INTRAUTERINE DELIVERY SYSTEM'},
{ value: 'oral_emulsion', label: 'ORAL EMULSION'},
{ value: 'oral_solution_solution_for_injection', label: 'ORAL SOLUTION, SOLUTION FOR INJECTION'},
{ value: 'film_coated_tablet_modified_release_tablet', label: 'FILM COATED TABLET, MODIFIED RELEASE TABLET'},
{ value: 'medicated_nail_lacquer', label: 'MEDICATED NAIL LACQUER'},
{ value: 'transdermal_spray_solution', label: 'TRANSDERMAL SPRAY, SOLUTION'},
{ value: 'powder_and_solvent_for_prolonged_release_suspension_for_injection', label: 'POWDER AND SOLVENT FOR PROLONGED RELEASE SUSPENSION FOR INJECTION'},
{ value: 'gel_intrauterine_liquid', label: 'GEL, INTRAUTERINE LIQUID'},
{ value: 'chewabledispersible_tablet', label: 'CHEWABLE/DISPERSIBLE TABLET'},
{ value: 'injection_solution_for_injectioninfusion', label: 'INJECTION, SOLUTION FOR INJECTION/INFUSION'},
{ value: 'enema_rectal_solution', label: 'ENEMA, RECTAL SOLUTION'},
{ value: 'gastro_resistant_tablet_prolonged_release_tablet', label: 'GASTRO RESISTANT TABLET, PROLONGED RELEASE TABLET'},
{ value: 'cutaneous_paste', label: 'CUTANEOUS PASTE'},
{ value: 'modified_release_capsule', label: 'MODIFIED RELEASE CAPSULE'},
{ value: 'powder_for_concentrate_for_solution_for_infusion_or_injection', label: 'POWDER FOR CONCENTRATE FOR SOLUTION FOR INFUSION OR INJECTION'},
{ value: 'compressed_lozenge', label: 'COMPRESSED LOZENGE'},
{ value: 'oral_suspension_rectal_suspension', label: 'ORAL SUSPENSION, RECTAL SUSPENSION'},
{ value: 'kit_for_radiopharmaceutical_preparation_powder_for_solution_for_injection', label: 'KIT FOR RADIOPHARMACEUTICAL PREPARATION, POWDER FOR SOLUTION FOR INJECTION'},
{ value: 'kit_for_radiopharmaceutical_preparation_solution_for_injection', label: 'KIT FOR RADIOPHARMACEUTICAL PREPARATION, SOLUTION FOR INJECTION'},
{ value: 'sublingual_spray', label: 'SUBLINGUAL SPRAY'},
{ value: 'film_coated_tablet_prolonged_release_tablet', label: 'FILM COATED TABLET, PROLONGED RELEASE TABLET'},
{ value: 'vaginal_delivery_system', label: 'VAGINAL DELIVERY SYSTEM'},
{ value: 'powder_for_solution_for_infusion_powder_for_solution_for_injection', label: 'POWDER FOR SOLUTION FOR INFUSION, POWDER FOR SOLUTION FOR INJECTION'},
{ value: 'chewing_gum', label: 'CHEWING GUM'},
{ value: 'solution_for_haemofiltration', label: 'SOLUTION FOR HAEMOFILTRATION'},
{ value: 'powder_and_suspension_for_suspension_for_injection', label: 'POWDER AND SUSPENSION FOR SUSPENSION FOR INJECTION'},
{ value: 'nasal_stick', label: 'NASAL STICK'},
{ value: 'rectal_suspension', label: 'RECTAL SUSPENSION'},
{ value: 'solution_for_haemodialysis', label: 'SOLUTION FOR HAEMODIALYSIS'},
{ value: 'concentrate_for_solution_for_infusion_or_injection_solution_for_injection', label: 'CONCENTRATE FOR SOLUTION FOR INFUSION OR INJECTION, SOLUTION FOR INJECTION'},
{ value: 'vaginal_capsule', label: 'VAGINAL CAPSULE'},
{ value: 'vaginal_gel', label: 'VAGINAL GEL'},
{ value: 'powder_and_solvent_for_solution_for_injection_in_pre_filled_syringe', label: 'POWDER AND SOLVENT FOR SOLUTION FOR INJECTION IN PRE FILLED SYRINGE'},
{ value: 'emulsion_for_infusion_or_injection', label: 'EMULSION FOR INFUSION OR INJECTION'},
{ value: 'soluble_tablet', label: 'SOLUBLE TABLET'},
{ value: 'powder', label: 'POWDER'},
{ value: 'lyophilisate_for_preparation_of_an_injectioninfusion_solution_or_a_solution_for_a_nebuliser', label: 'LYOPHILISATE FOR PREPARATION OF AN INJECTION/INFUSION SOLUTION OR A SOLUTION FOR A NEBULISER'},
{ value: 'radionuclide_generator', label: 'RADIONUCLIDE GENERATOR'},
{ value: 'powder_for_infusion', label: 'POWDER FOR INFUSION'},
{ value: 'orodispersible_film', label: 'ORODISPERSIBLE FILM'},
{ value: 'inhalation_vapour_solution', label: 'INHALATION VAPOUR, SOLUTION'},
{ value: 'inhalation_powder_hard_capsule', label: 'INHALATION POWDER, HARD CAPSULE'},
{ value: 'hard_capsule_inhalation_powder', label: 'HARD CAPSULE, INHALATION POWDER'},
{ value: 'solvent_for_parenteral_use', label: 'SOLVENT FOR PARENTERAL USE'},
{ value: 'powder_and_solvent_for_sealant', label: 'POWDER AND SOLVENT FOR SEALANT'},
{ value: 'solution_for_sealant', label: 'SOLUTION FOR SEALANT'},
{ value: 'oral_solution_powder_and_solvent_for_solution_for_infusion_or_injection', label: 'ORAL SOLUTION, POWDER AND SOLVENT FOR SOLUTION FOR INFUSION OR INJECTION'},
{ value: 'oral_solution_solution_for_injectioninfusion', label: 'ORAL SOLUTION, SOLUTION FOR INJECTION/INFUSION'},
{ value: 'oral_solution_powder_for_solution_for_injectioninfusion', label: 'ORAL SOLUTION, POWDER FOR SOLUTION FOR INJECTION/INFUSION'},
{ value: 'powder_and_solvent_for_oral_solution_powder_and_solvent_for_solution_for_infusion_or_injection', label: 'POWDER AND SOLVENT FOR ORAL SOLUTION, POWDER AND SOLVENT FOR SOLUTION FOR INFUSION OR INJECTION'},
{ value: 'bladder_irrigation', label: 'BLADDER IRRIGATION'},
{ value: 'vaginal_soft_capsule', label: 'VAGINAL SOFT CAPSULE'},
{ value: 'chewable_tablet_film_coated_tablet', label: 'CHEWABLE TABLET, FILM COATED TABLET'},
{ value: 'solution_for_infusion_infusion', label: 'SOLUTION FOR INFUSION, INFUSION'},
{ value: 'powder_for_oral_solution_powder_for_solution_for_infusion', label: 'POWDER FOR ORAL SOLUTION, POWDER FOR SOLUTION FOR INFUSION'},
{ value: 'powder_for_concentrate_for_solution_for_infusion_powder_for_oral_solution', label: 'POWDER FOR CONCENTRATE FOR SOLUTION FOR INFUSION, POWDER FOR ORAL SOLUTION'},
{ value: 'solution_for_infusion_solution_for_injection', label: 'SOLUTION FOR INFUSION, SOLUTION FOR INJECTION'},
{ value: 'pressurised_inhalation', label: 'PRESSURISED INHALATION'},
{ value: 'inhalation_vapour_ointment', label: 'INHALATION VAPOUR, OINTMENT'},
{ value: 'cutaneous_spray', label: 'CUTANEOUS SPRAY'},
{ value: 'powder_and_solvent_for_cutaneous_solution', label: 'POWDER AND SOLVENT FOR CUTANEOUS SOLUTION'},
{ value: 'powder_for_solutionsuspension_for_injection', label: 'POWDER FOR SOLUTION/SUSPENSION FOR INJECTION'},
{ value: 'oral_powder_powder_for_solution_for_infusion', label: 'ORAL POWDER, POWDER FOR SOLUTION FOR INFUSION'},
{ value: 'oral_powder_powder_for_concentrate_for_solution_for_infusion', label: 'ORAL POWDER, POWDER FOR CONCENTRATE FOR SOLUTION FOR INFUSION'},
{ value: 'injection_intravenous_infusion', label: 'INJECTION, INTRAVENOUS INFUSION'},
{ value: 'solution_for_injectioninfusion',label:'SOLUTION FOR INJECTION INFUSION'}
  ]

  const RouteOptions = [{ value: 'oral', label: 'ORAL' },
  { value: 'topical_skin', label: 'TOPICAL SKIN' },
  { value: 'topical-eyes', label: 'TOPICAL EYE' },
  { value: 'topical_ears', label: 'TOPICAL EARS' },
  { value: 'inhalation', label: 'INHALATION' },
  { value: 'injections', label: 'INJECTIONS' },
  { value: 'vaginal', label: 'VAGINAL' },
  { value: 'anal', label: 'ANAL' },
  { value: 'sub_lingual', label: 'SUB LINGUAL' }

  ]
  const Strengthoptions = [{ value: 'mg', label: 'mg' },
  { value: 'g', label: 'g' },
  { value: 'ml', label: 'mL' },
  { value: 'mcg', label: 'mcg' },
  { value: 'mEq', label: 'mEq' },
  { value: 'iu', label: 'IU' },
  ]

  const medicinclasses = [
    { value:'drugs_for_acid_related_disorders', label: 'DRUGS FOR ACID RELATED DISORDERS' },
{ value:'drugs_for_functional_gastrointestinal_disorders', label: 'DRUGS FOR FUNCTIONAL GASTROINTESTINAL DISORDERS' },
{ value:'antiemetics_and_antinauseants', label: 'ANTIEMETICS AND ANTINAUSEANTS' },
{ value:'bile_and_liver_therapy', label: 'BILE AND LIVER THERAPY' },
{ value:'drugs_for_constipation', label: 'DRUGS FOR CONSTIPATION' },
{ value:'antidiarrheals_intestinal_antiinflammatoryantiinfective_agents', label: 'ANTIDIARRHEALS, INTESTINAL ANTIINFLAMMATORY/ANTIINFECTIVE AGENTS' },
{ value:'drugs_used_in_diabetes', label: 'DRUGS USED IN DIABETES' },
{ value: 'vitamins', label: 'VITAMINS' },
{ value: 'antithrombotic_agents', label: 'ANTITHROMBOTIC AGENTS' },
{ value: 'antihemorrhagics', label: 'ANTIHEMORRHAGICS' },
{ value: 'antianemic_preparations', label: 'ANTIANEMIC PREPARATIONS' },
{ value: 'blood_substitutes_and_perfusion_solutions', label: 'BLOOD SUBSTITUTES AND PERFUSION SOLUTIONS' },
{ value: 'cardiac_therapy', label: 'CARDIAC THERAPY' },
{ value: 'diuretics', label: 'DIURETICS' },
{ value: 'peripheral_vasodilators', label: 'PERIPHERAL VASODILATORS' },
{ value: 'vasoprotectives', label: 'VASOPROTECTIVES' },
{ value: 'beta_blocking_agents', label: 'BETA BLOCKING AGENTS' },
{ value: 'calcium_channel_blockers', label: 'CALCIUM CHANNEL BLOCKERS' },
{ value: 'agents_acting_on_the_renin-angiotensin_system', label: 'AGENTS ACTING ON THE RENIN-ANGIOTENSIN SYSTEM' },
{ value: 'lipid_modifying_agents', label: 'LIPID MODIFYING AGENTS' },
{ value: 'antipruritics_incl._antihistamines_anesthetics_etc.', label: 'ANTIPRURITICS, INCL. ANTIHISTAMINES, ANESTHETICS, ETC.' },
{ value: 'antipsoriatics', label: 'ANTIPSORIATICS' },
{ value: 'antibiotics_and_chemotherapeutics_for_dermatological_use', label: 'ANTIBIOTICS AND CHEMOTHERAPEUTICS FOR DERMATOLOGICAL USE' },
{ value: 'corticosteroids_dermatological_preparations', label: 'CORTICOSTEROIDS, DERMATOLOGICAL PREPARATIONS' },
{ value: 'antiseptics_and_disinfectants', label: 'ANTISEPTICS AND DISINFECTANTS' },
{ value: 'anti-acne_preparations', label: 'ANTI-ACNE PREPARATIONS' },
{ value: 'other_dermatological_preparations', label: 'OTHER DERMATOLOGICAL PREPARATIONS' },
{ value: 'gynecological_antiinfectives_and_antiseptics', label: 'GYNECOLOGICAL ANTIINFECTIVES AND ANTISEPTICS' },
{ value: 'other_gynecologicals', label: 'OTHER GYNECOLOGICALS' },
{ value: 'sex_hormones_and_modulators_of_the_genital_system', label: 'SEX HORMONES AND MODULATORS OF THE GENITAL SYSTEM' },
{ value: 'urologicals', label: 'UROLOGICALS' },
{ value: 'corticosteroids_for_systemic_use', label: 'CORTICOSTEROIDS FOR SYSTEMIC USE' },
{ value: 'antibacterials_for_systemic_use', label: 'ANTIBACTERIALS FOR SYSTEMIC USE' },
{ value: 'antimycotics_for_systemic_use', label: 'ANTIMYCOTICS FOR SYSTEMIC USE' },
{ value: 'antimycobacterials', label: 'ANTIMYCOBACTERIALS' },
{ value: 'antivirals_for_systemic_use', label: 'ANTIVIRALS FOR SYSTEMIC USE' },
{ value: 'vaccines', label: 'VACCINES' },
{ value: 'antineoplastic_agents', label: 'ANTINEOPLASTIC AGENTS' },
{ value: 'endocrine_therapy', label: 'ENDOCRINE THERAPY' },
{ value: 'antiinflammatory_and_antirheumatic_products', label: 'ANTIINFLAMMATORY AND ANTIRHEUMATIC PRODUCTS' },
{ value: 'muscle_relaxants', label: 'MUSCLE RELAXANTS' },
{ value: 'antigout_preparations', label: 'ANTIGOUT PREPARATIONS' },
{ value: 'drugs_for_treatment_of_bone_diseases', label: 'DRUGS FOR TREATMENT OF BONE DISEASES' },
{ value: 'anesthetics', label: 'ANESTHETICS' },
{ value: 'analgesics', label: 'ANALGESICS' },
{ value: 'antiepileptics', label: 'ANTIEPILEPTICS' },
{ value: 'anti-parkinson_drugs', label: 'ANTI-PARKINSON DRUGS' },
{ value: 'psycholeptics', label: 'PSYCHOLEPTICS' },
{ value: 'psychoanaleptics', label: 'PSYCHOANALEPTICS' },
{ value: 'other_nervous_system_drugs', label: 'OTHER NERVOUS SYSTEM DRUGS' },
{ value: 'antiprotozoals', label: 'ANTIPROTOZOALS' },
{ value: 'nasal_preparations', label: 'NASAL PREPARATIONS' },
{ value: 'throat_preparations', label: 'THROAT PREPARATIONS' },
{ value: 'drugs_for_obstructive_airway_diseases', label: 'DRUGS FOR OBSTRUCTIVE AIRWAY DISEASES' },
{ value: 'cough_and_cold_preparations', label: 'COUGH AND COLD PREPARATIONS' },
{ value: 'antihistamines_for_systemic_use', label: 'ANTIHISTAMINES FOR SYSTEMIC USE' },
{ value: 'ophthalmologicals', label: 'OPHTHALMOLOGICALS' },
{ value: 'all_other_therapeutic_products', label: 'ALL OTHER THERAPEUTIC PRODUCTS' },
{ value: 'other_hematological_agents', label: 'OTHER HEMATOLOGICAL AGENTS' },
{ value: 'antihypertensives', label: 'ANTIHYPERTENSIVES' },
{ value: 'emollients_and_protectives', label: 'EMOLLIENTS AND PROTECTIVES' },
{ value: 'ectoparasiticides_incl_scabicides,_insecticides_and_repellents', label: 'ECTOPARASITICIDES, INCL. SCABICIDES, INSECTICIDES AND REPELLENTS' },
{ value: 'other_respiratory_system_products', label: 'OTHER RESPIRATORY SYSTEM PRODUCTS' },
{ value: 'stomatological_preparations', label: 'STOMATOLOGICAL PREPARATIONS' },
{ value: 'digestives_incl_enzymes', label: 'DIGESTIVES, INCL. ENZYMES' },
{ value: 'mineral_supplements', label: 'MINERAL SUPPLEMENTS' },
{ value: 'other_alimentary_tract_and_metabolism_products', label: 'OTHER ALIMENTARY TRACT AND METABOLISM PRODUCTS' },
{ value: 'antifungals_for_dermatological_use', label: 'ANTIFUNGALS FOR DERMATOLOGICAL USE' },
{ value: 'preparations_for_treatment_of_wounds_and_ulcers', label: 'PREPARATIONS FOR TREATMENT OF WOUNDS AND ULCERS' },
{ value: 'thyroid_therapy', label: 'THYROID THERAPY' },
{ value: 'calcium_homeostasis', label: 'CALCIUM HOMEOSTASIS' },
{ value: 'immune_sera_and_immunoglobulins', label: 'IMMUNE SERA AND IMMUNOGLOBULINS' },
{ value: 'immunosuppressants', label: 'IMMUNOSUPPRESSANTS' },
{ value: 'otologicals', label: 'OTOLOGICALS' },
{ value: 'contrast_media', label: 'CONTRAST MEDIA' },
{ value: 'anabolic_agents_for_systemic_use', label: 'ANABOLIC AGENTS FOR SYSTEMIC USE' },
{ value: 'appetite_stimulants', label: 'APPETITE STIMULANTS' },
{ value: 'pituitary_and_hypothalamic_hormones_and_analogues', label: 'PITUITARY AND HYPOTHALAMIC HORMONES AND ANALOGUES' },
{ value: 'topical_products_for_joint_and_muscular_pain', label: 'TOPICAL PRODUCTS FOR JOINT AND MUSCULAR PAIN' },
{ value: 'diagnostic_radiopharmaceuticals', label: 'DIAGNOSTIC RADIOPHARMACEUTICALS' },
{ value: 'anthelmintics', label: 'ANTHELMINTICS' },
{ value: 'pancreatic_hormones', label: 'PANCREATIC HORMONES' },
{ value: 'immunostimulants', label: 'IMMUNOSTIMULANTS' },
{ value: 'ophthalmological_and_otological_preparations', label: 'OPHTHALMOLOGICAL AND OTOLOGICAL PREPARATIONS' },
{ value: 'diagnostic_agents', label: 'DIAGNOSTIC AGENTS' },
{ value: 'therapeutic_radiopharmaceuticals', label: 'THERAPEUTIC RADIOPHARMACEUTICALS' },
{ value: 'other_drugs_for_disorders_of_the_musculo-skeletal_system', label: 'OTHER DRUGS FOR DISORDERS OF THE MUSCULO-SKELETAL SYSTEM' },
{ value: 'tonics', label: 'TONICS' },
{ value: 'all_other_non-therapeutic_products', label: 'ALL OTHER NON-THERAPEUTIC PRODUCTS'}
    
    
  
  ]

  const handleServiceChange = (data) => {
    Setdosage_type(data)
  }
  const strengthChange = (data) => {
    setStrengthUnit(data)
  }

  const handleUnitChange = (data) => {

    setRoutine(data)
  }
  const handleMedicineType = (data) => {
    setMedicinetype(data);

  }
  const customStyles = {
    control: base => ({
      ...base,
      width: 150,
      minHeight: 35
    })
  };
  const validate = () => {

    let input = data;
    let errors = {};
    let isValid = true;
    setError({});


    if (!input["med_name"] || input["med_name"].trim() === "") {
      isValid = false;
      errors["medicinename"] = "Enter generic name of Name";
    }
    // if (!input["generic_name"] || input["generic_name"] === "") {
    //   isValid = false;
    //   errors["genericname"] = "Enter generic name of medicine";
    // }
    if (!input["strength"] || input["strength"].trim()=== "") {
      isValid = false;
      errors["strength"] = "Enter strength of medicine";
    }
    if (!input["med_type"] || input["med_type"] === "") {
      isValid = false;
      errors["medicinetype"] = "Enter medtype";
    }
    if (!input["dosage_form"] || input["dosage_form"] === "") {
      isValid = false;
      errors["dosage_type"] = "Select dosage type";
    }
    if (!input["med_code"] || input["med_code"].trim() === "") {
      isValid = false;
      errors["medicinecode"] = "Enter medicine code";
    }
    // if (!input["route"] || input["route"] === "") {
    //   isValid = false;
    //   errors["routine"] = "Enter route of medicine";
    // }
    if (strengthunit.value === ""||strengthunit.value === null) {
      isValid = false;
      errors["strength_unit"] = "Select strength unit of medicine";
    }
    // if (!input["composition"] || input["composition"] === "") {
    //   isValid = false;
    //   errors["composition"] = "Enter composition of medicine";
    // }
    if (
      input["med_url"] !== "" &&
      input["med_url"] !== null
    ) {
      var regex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      var pattern = new RegExp(regex);
      if (!pattern.test(input["med_url"])) {
        isValid = false;
        errors["med_url"] = "Enter a valid url.";
       
      }
    }



    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));


    return isValid;
  }
 

  const handleTitle = (e) => {

    const val = e.target.value;
    const key = e.target.name;
    setData(current => ({
      ...current, [key]: key==="med_code"?val.replace(/\s+/g, ''):val
    }))
    setError(currentstate => ({
      ...currentstate,
      [key]:""
    }));
  }



  const saveChanges = () => {
  
    if (validate()) {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
      let dataToSend = new FormData();
      if (props.data.med_name !== data.med_name) {
        dataToSend.append('med_name', data.med_name.trim());
      }
      if (props.data.strength !== data.strength) {
        dataToSend.append('strength', data.strength.trim());
      }
      if (props.data.med_type !== med_type.value) {
        dataToSend.append('med_type',med_type.value);
      }
      if (props.data.composition !== data.composition) {
        dataToSend.append('composition', data.composition.trim());
      }
      if (props.data.strength_unit !== strengthunit.value) {
        dataToSend.append('strength_unit', strengthunit.value);
      }
      if (props.data.dosage_form !== dosage_type.value) {
        dataToSend.append('dosage_form', dosage_type.value);
      }
      if (props.data.med_code !== data.med_code) {
        dataToSend.append('med_code', data.med_code);
      }
      if (props.data.route !== routine.value) {
        dataToSend.append('route', routine.value);
      }
      if (props.data.description !== data.description) {
        dataToSend.append('description', data.description.trim());
      }
      if (props.data.med_interactions !== data.med_interactions) {
        dataToSend.append('med_interactions', data.med_interactions.trim());
      }
      if (props.data.med_url !== data.med_url) {
        dataToSend.append('med_url', data.med_url.trim());
      }
      if (props.data.manufacturer_name !== data.manufacturer_name) {
        dataToSend.append('manufacturer_name', data.manufacturer_name.trim());
      }
      if (props.data.manufacturer_address !== data.manufacturer_address) {
        dataToSend.append('manufacturer_address', data.manufacturer_address.trim());
      }
     

      axios
        .put(`${APIURL}/api/v1/service-provider/master-medicine-detail/${data.id}/`, dataToSend, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
           alert.success(res.data.message)
           props.modalClose()
            setData({
              med_name: '',
              generic_name: '',
              strength: '',
              composition: '',
              medicinetype: { value: '', label: '' },
              dosage_type: { value: '', label: '' },
              strengthunit: { value: '', label: '' },
              med_code: '',
              routine: { value: '', label: '' },
              description: '',
              med_interactions: null,
              med_url: null,
              manufacturername: ''
            });
          }
          else {
            alert.error(res.data.message)
          
          }
        })
        .catch((err) => {
          
        });
      setSuccessShow(false)
    }
  }
  const handleImageUpload = (event) => {
    const image = event.target.files[0];
    setData(prevData => ({ ...prevData, image: URL.createObjectURL(image) }));
    setSelectedImage(image);
  };




  return (
    <>
      <h4 style={{ marginTop: '0' }} className="title-of-page">Edit Medicine</h4>
      <div className='fit-content'>
        <div className='feature-data pad-features'>
          <div className='row'style={{textAlign:"left",width:"100%"}}>
       
          <div className='col-8'>
            <label >*Generic Name : </label>
            <input type='text'maxLength="300" style={{textAlign:"left",width:"100%",height:"45px"}} className='css-1s2u09g-control1' name='med_name' value={data.med_name} onChange={handleTitle} />
            {error.medicinename ? <div className="error-validation-msg error-feature-inventory">{error.medicinename}</div> : null}
          </div>
          <div className='col-4'>
            <label> *ATC Code : </label>
            <input maxLength="20"  type='text' style={{textAlign:"left",width:"100%",height:"45px"}} className='css-1s2u09g-control1' name='med_code' value={data.med_code} onChange={handleTitle} />
            {error.medicinecode ? <div className="error-validation-msg error-feature-inventory">{error.medicinecode}</div> : null}
          </div>
</div>
<div className='row'style={{textAlign:"left",width:"100%",marginTop:"2%"}}>
         
         
          
          <div className='col-4'>
            <label >*Strength: </label>
            <input type='text'maxLength="50" style={{textAlign:"left",width:"100%",height:"45px"}} className='css-1s2u09g-control1' name='strength' value={data.strength} onChange={handleTitle} />
            {error.strength ? <div className="error-validation-msg error-feature-inventory">{error.strength}</div> : null}
          </div>
          <div className='col-4'>
                <label >*Strength Unit: </label>
                
                <Select
                           defaultValue={strengthunit}
                           style={{width:"100%"}}
                            onChange={(data,e)=>{strengthChange(data,e)
                              setError(currentstate => ({
                                ...currentstate,
                                strength_unit:""
                              }));
                            }}
                            options={Strengthoptions}
                            name='strength_unit'
                            // className="css-1s2u09g-control1"
                        />
                          {error.strength_unit ? <div className="error-validation-msg error-feature-inventory">{error.strength_unit}</div> : null}
                        </div>
                    
                        <div className='col-4'>
            <label > Manufacturer Name : </label>
            <input type='text'maxLength="100" style={{textAlign:"left",width:"100%",height:"45px"}} className='css-1s2u09g-control1' name='manufacturer_name' value={data.manufacturer_name!==null?data.manufacturer_name:""} onChange={handleTitle} />
            {/* {error.manufacturername ? <div className="error-validation-msg error-feature-inventory">{error.manufacturername}</div> : null} */}
          </div>
</div>
<div className='row'style={{textAlign:"left",width:"100%",marginTop:"2%"}}>
<div className='col-4' >
                    <label >Manufacturer address: </label>
                    <textarea type='text'maxLength="400" style={{height:"100px",width:"100%"}} className='css-1s2u09g-control1' name='manufacturer_address' value={data.manufacturer_address!==undefined?data.manufacturer_address:""} onChange={handleTitle} />
                    {/* {error.composition? <div className="error-validation-msg error-feature-inventory">{error.composition}</div> : null} */}
                </div>

          <div className='col-4' >
                    <label >Composition: </label>
                    <textarea type='text'maxLength="400" style={{height:"100px",width:"100%"}} className='css-1s2u09g-control1' name='composition' value={data.composition!==null?data.composition:""} onChange={handleTitle} />
                    {error.composition? <div className="error-validation-msg error-feature-inventory">{error.composition}</div> : null}
                </div>
                <div className='col-4'>
            <label > Description : </label>
            <textarea type='text' maxLength="500" style={{height:"100px",width:"100%"}} className='css-1s2u09g-control1 ' name='description' value={data.description} onChange={handleTitle} />
            {/* {error.description ? <div className="error-validation-msg error-feature-inventory">{error.description}</div> : null} */}
          </div>
          </div>
          <div className='row'style={{textAlign:"left",width:"100%",marginTop:"2%"}}>
          <div className='col-4'>
            <label >*Medicine Type : </label>
            <Select
             defaultValue={med_type}
              onChange={(data, e) => {
                handleMedicineType(data, e);
                setError(currentstate => ({
                  ...currentstate,
                  medicinetype:""
                }));
              }}
              options={medicinclasses}
              name='medicinetype'
              className="css-1s2u09g-control11"
              style={{width:"100%"}}
            />
            {error.medicinetype ? <div className="error-validation-msg error-feature-inventory">{error.medicinetype}</div> : null}
          </div>
          <div className='col-4' >
            <label >*Dosage: </label>
            <Select
              defaultValue={dosage_type}
              onChange={(data, e) => {
                handleServiceChange(data, e);
                setError(currentstate => ({
                  ...currentstate,
                  dosage_type:""
                }));
              }}
              options={DosageOptions}
              name='dosage_type'
              // className="css-1s2u09g-control1"
              style={{width:"100%"}}
            />
            {error.dosage_type ? <div className="error-validation-msg error-feature-inventory">{error.dosage_type}</div> : null}
          </div>
          <div className='col-4' >
            <label >Route: </label>
            <Select
              defaultValue={routine}
              onChange={(data, e) => {
                handleUnitChange(data, e);
                setError(currentstate => ({
                  ...currentstate,
                  routine:""
                }));
              }}
              options={RouteOptions}
              name='route'
              style={{width:"100%"}}
              // className="css-1s2u09g-control1"
            />
            {error.routine ? <div className="error-validation-msg error-feature-inventory">{error.routine}</div> : null}
          </div>
</div>
<div className='row'style={{textAlign:"left",width:"100%",marginTop:"2%"}}>
          
          <div className='col-6'>
            <label > Drug Interactions : </label>
            <textarea type='text'style={{height:"100px",width:"100%"}} className='css-1s2u09g-control1' name='med_interactions' value={data.med_interactions} onChange={handleTitle} />
            {/* {error.description ? <div className="error-validation-msg error-feature-inventory">{error.description}</div> : null} */}
          </div>
         
          <div className='col-6'>
            <label > Drug Interactions URL : </label>
            <textarea type='text'style={{height:"100px",width:"100%"}} placeholder="https://www.example.com" className='css-1s2u09g-control1' name='med_url' value={data.med_url} onChange={handleTitle} />
            {error.med_url ? <div className="error-validation-msg error-feature-inventory">{error.med_url}</div> : null}
          </div>
          </div>
        </div>


      <div className='text-center'>
        <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>

      </div>


     

    </div>
     
    </>
  )
}

export default EditMedicine
