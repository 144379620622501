import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL, TZ } from "../../Global";
import { useAlert } from 'react-alert'
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { debounce } from 'lodash';
function AddRemark(props) {

  const [remarkData, setRemarkData] = useState('')
  const [comments, setComments] = useState([])
  const [refresh, setRefresh] = useState(false)
  const alert = useAlert()
let id=props.trackId
  const [nurseRemarks, setNurseRemarks] = useState([])
  const [docRemarks, setDocRemarks] = useState([])
  const [pharmRemarks, setPharmRemarks] = useState([])
  const login_datas = (
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  const [selected, setSelected] = useState(login_datas.user_type === "hospital_doctor" ? "doctor" : "pharm")


  
    const fetchRemarks = () => {
      const token = sessionStorage.getItem("usertoken").replace(/["]+/g, "");
      axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setDocRemarks(res.data.doctor_remarks);
          setPharmRemarks(res.data.pharmacist_remarks);
          setNurseRemarks(res.data.nurse_remarks);
        }
      })
      .catch((err) => {
        console.error("Failed to fetch remarks:", err);
      });
    };
    useEffect(() => {
    fetchRemarks();
  }, [id]);

  const callSave = () => {


    // if (validate(rowToUpdate)) {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');


    let remarks = remarkData


    const dataToUpdate = {
      remarks,

    };
    let url = login_datas.user_type === "hospital_doctor" ? `/api/v1/doctor/patient-prescriptions-detail/${id}/` :
      `/api/v1/doctor/pharmacy-fulfillment/${id}/`




    axios.put(`${APIURL}${url}`, dataToUpdate, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then((res) => {
        if (res.data.status === "success") {

          setRemarkData("")
          alert.success("Remarks added !!")
          fetchRemarks(); 


        } else {


        }
      })
      .catch((err) => {

      });

  }
  const generateIcon = (name, type) => {
    let initials = ""
    if (type === "doctor") {
      initials = name
        .split(" ").slice(1, 3)
        .map((word) => word[0])
        .join("")
        .toUpperCase();
    }
    else {
      initials = name
        .split(" ").slice(0, 2)
        .map((word) => word[0])
        .join("")
        .toUpperCase();
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
      >
        <circle
          cx="22.5"
          cy="22.5"
          r="17.5"
          stroke="rgb(60, 104, 158)"
          stroke-width="2"
          fill="none"
        />

        <circle cx="22.5" cy="22.5" r="17.5" fill="white" />

        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="14"
          fontWeight="bold"
          fill="black"
        >
          {initials}
        </text>
      </svg>
    );
  };
  const rmkDiv = (
    <div className='row ' style={{ width: "100%", margin: "auto", }}>

      <div className="patientRecbutton1 row"
        style={{

          marginLeft: "10px",
          borderRadius: "10px",
          border:
            "1.124px solid rgba(88, 182, 222, 0.15)",
          background: "#F0F9FD",
          fontSize: "12px",

        }}


      >
        <div className=" col-2">
        {login_datas.user_type === "hospital_doctor" ? generateIcon(login_datas.name, "doctor") : generateIcon(login_datas.name)}
        </div>
        
        <input
          type="text"
          className=" col-8"
          value={remarkData}
          name="remarks"
          placeholder='Add your remark here'
          style={{
            borderLeft: "none",
            borderRight: "none",
            borderTop: "none",
            borderBottom: "1px solid black",


          }}
          onChange={(e) => {

          
            setRemarkData(e.target.value)
          }}
          autoComplete="off"
        />
    <button disabled={remarkData === ''}
    
        style={{
          backgroundColor:"rgb(60, 104, 158)",
          borderRadius: '5px',
          color:"white",
         

        }} onClick={callSave} className=' btn-primary col-2'>Save</button>
      </div>

     
  
      <br />
    </div>
  )
  const DocRmkDisplay = <>
  {login_datas.user_type === "hospital_doctor" && !props.from&&rmkDiv}
  {docRemarks && docRemarks.length > 0 &&

    docRemarks !== null && docRemarks.length > 0 &&
    <SimpleBar style={{ maxHeight: "200px", width: "100%", padding: "1%" }}>
    <div style={{ textAlign: "left" }}>
     
      {docRemarks.map((item) => {
        return (
          <div className='row' style={{ textAlign: "left", backgroundColor: "aliceblue" }}>
            <h6 style={{ color: "rgb(0, 101, 124)", paddingTop: "1%",fontSize:"14px",textTransform:"capitalize" }} className='col-5'>{item.doctor_name}<br />
              <small className='' style={{ color: "CaptionText" }}>{item.date.slice(0, 10)}</small>
            </h6>
            <p className='break-word col-7'>{item.remark}</p>
          </div>
        )

      })}

    </div>
    </SimpleBar>}</>

  const PharmRmkDisplay = <>
   {login_datas.user_type === "hospital_pharmacist" && !props.from&&rmkDiv}
  {pharmRemarks && pharmRemarks.length > 0 &&

    pharmRemarks !== null && pharmRemarks.length > 0 &&
    <SimpleBar style={{ maxHeight: "200px", width: "100%", padding: "1%" }}>
    <div style={{ textAlign: "left", }}>
     
      {pharmRemarks.map((item) => {
        return (
          <div className='row' style={{ textAlign: "left", backgroundColor: "aliceblue" }}>
            <h6 style={{ color: "rgb(0, 101, 124)", paddingTop: "1%", fontSize:"14px",textTransform:"capitalize"}} className='col-5'>{item.pharmacist_name}<br />
              <small className='' style={{ color: "CaptionText" }}>{item.date.slice(0, 10)}</small>
            </h6>
            <p className='col-7 break-word'>{item.remark}</p>
          </div>
        )

      })}

    </div>
    </SimpleBar>
    }</>
    const NurseRmkDisplay = <>
     
  {nurseRemarks && nurseRemarks.length > 0 &&

    nurseRemarks !== null && nurseRemarks.length > 0 &&
    <SimpleBar style={{ maxHeight: "200px", width: "100%", padding: "1%" }}>
    <div style={{ textAlign: "left" }}>
     
      {nurseRemarks.map((item) => {
        return (item.length>0&&item.map((i)=>{
          return(
          <div className='row' style={{ textAlign: "left", backgroundColor: "aliceblue" }}>
          <h6 style={{ color: "rgb(0, 101, 124)", paddingTop: "1%",fontSize:"14px",textTransform:"capitalize" }} className='col-5'>{i.nurse_name}<br />
            <small className='' style={{ color: "CaptionText" }}>{i.date}-{i.time}</small>
          </h6>
          <p className='break-word col-7'>{i.remark}</p>
        </div>
          )
        })
         
        )

      })}
        
 
     </div>
     </SimpleBar>
     }</>




  return (
    <div className=''>

      <div className='row' style={{margin:"auto"}}>
        <div className={login_datas.user_type === "hospital_doctor"?(selected === "doctor" ? "col-4 selectedoption" : 'col-4 selectoptions'):(selected === "doctor" ? "col-6 selectedoption" : 'col-6 selectoptions')} onClick={() => { setSelected("doctor") }} >
          Doctor
        </div>
        <div className={login_datas.user_type === "hospital_doctor"?(selected === "pharm" ? "col-4 selectedoption" : 'col-4 selectoptions'):(selected === "pharm" ? "col-6 selectedoption" : 'col-6 selectoptions')} onClick={() => { setSelected("pharm") }} >
          Pharmacist
        </div>
        {login_datas.user_type === "hospital_doctor" &&<div className={selected === "nurse" ? "col-4 selectedoption" : 'col-4 selectoptions'} onClick={() => { setSelected("nurse") }} >
          Nurse
        </div>}
      </div>
      <> <br />
        <div className="">

          
            {selected === "doctor" ? DocRmkDisplay : selected === "pharm" ? PharmRmkDisplay :NurseRmkDisplay}
         

        </div>
      </>

    </div>



  )
}
export default React.memo(AddRemark);
