import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import { APIURL, TZ } from "../../../Global";
import "simplebar-react/dist/simplebar.min.css";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useAlert } from "react-alert";
import moment from "moment";

function ManageTest(props) {
  const [testData1, setTestData1] = useState([
    {
      id: "",
      test_name: "",
      date: "",
      lab_test_details: "",
    },
  ]);
  const [testRefresh, setTestRefresh] = useState(false);
  const [options, setOptions] = useState([]);
  const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
  const [n1, setn1] = useState(3)
  const [deleteTestConfirmModalShow, setDeleteTestConfirmModalShow] =
    useState(false);
  const [dateVal, setDateVal] = useState("");
  const [search, setSearch] = useState("");
  const [, setCurrentColumn] = useState("");
  const [testIndexToDelete, setTestIndexToDelete] = useState();
  const [addtest, setAddtest] = useState(true);
  const [render, setRender] = useState(false);
  const [permission, setPermission] = useState(false);
  const [submitTrigger, setSubmitTrigger] = useState(true);
  const [updatedTestList, setUpdatedTestList] = useState([]);
  const [testErr, setTestErr] = useState("");
  let [n, setN] = useState(0);
  const [testListCounter, setTestListCounter] = useState(0);
  const alert = useAlert();
  const [deleteTestConfirmMsg, setDeleteTestConfirmMsg] = useState("");
  const wrapperRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState("");
  const [display1, setDisplay1] = useState(false);
  const [doctorName, setDoctorName] = useState("");
  const [testDate, setTestDate] = useState();
  const [testsList, setTestsList] = useState([
    {
      id: "",
      test_name: "",
      date: "",
      lab_test_details: "",
      created_by: "",
      hospital_name: ""
    },
  ]);
  const delConfirmRef = useRef(null)
  useEffect(() => {
    const handleKey = (e) => {

      if (e.altKey && e.key === 's') {
        e.preventDefault()
        delConfirmRef.current.click()
      } else if (e.key === 'Enter') {
        e.preventDefault()
        delConfirmRef.current.click()
      } else if (e.altKey && e.key === 'c') {
        e.preventDefault()
        setDeleteTestConfirmModalShow(false);

      }

    }
    document.addEventListener("keydown", handleKey);

    return () => {
      document.removeEventListener("keydown", handleKey);
    }

  }, []);
  useEffect(() => {

    TestData();

  }, [testRefresh]);
  useEffect(() => { if (testsList.length > 2) { setn1(testsList.length + 1) } }, [testsList])
  const TestData = async () => {
    if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") {
      setTestsList([
        {
          id: "",
          test_name: "",
          date: "",
          lab_test_details: "",
        },
      ]);
      setTestListCounter(0);
      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");
      await axios
        .get(`${APIURL}/api/v1/doctor/tests-list/?patient_id=${props.id}&${TZ}`, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setN(res.data.message.length);
            if (res.data.message.length > 0) {
              setTestListCounter(res.data.message.length);
              setTestsList(res.data.message);
              const today = new Date();
              const offset = today.getTimezoneOffset();
              let dateUpdated = new Date(today.getTime() - offset);
              const m = moment(dateUpdated, "YYYY-MM-DD");

              for (let index = 0; index < res.data.message.length; index++) {
                let ot = moment.utc(res.data.message[index].date).toDate();
                var ms = moment(ot, "YYYY-MM-DD");
                let timediff = moment
                  .duration(
                    moment(ms, "YYYY-MM-DD").diff(moment(m, "YYYY-MM-DD"))
                  )
                  .asDays();
                if (timediff >= 0) {

                  setTestDate(res.data.message[index].date);

                  break;
                } else {
                  setTestDate("");
                }
              }
            }
          }
        });
    }
  };
  useEffect(() => {
    if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") {
      if (permission === true) {
        const tokenString = sessionStorage.getItem("usertoken");
        const userid = sessionStorage.getItem("userid");
        let str = tokenString.replace(/["]+/g, "");
        if (testErr === "nil") {
          let values = [];
          let labtests = {};
          const tests = updatedTestList;
          for (let i = n; i < tests.length; i++) {
            values.push({
              patient: props.id,
              doctor: userid,
              lab_test_details: tests[i].lab_test_details,
              date: tests[i].date,
              test: tests[i].id,
            });
          }
          labtests = {
            labtests: values,
          };
          axios
            .post(`${APIURL}/api/v1/doctor/tests-list/`, labtests, {
              headers: {
                Authorization: "Token " + str,
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              if (res.data.status === "success") {
                alert.success("successfully submitted")
                setPermission(false);
                setAddtest(true)
                setDoctorName(login_datas.doctor_name);
                setTestRefresh(!testRefresh)

              } else {
                alert.error(res.data.message);
                setAddtest(true);
                setPermission(false);
              }
            })
            .catch((err) => {

              setAddtest(true);
              setPermission(false);
            });
        }
      }
    }
  }, [testErr !== "nil", submitTrigger]);

  const handleUpdateTest = (i) => {
    if (testsList[i].date === "") {
      alert.error("test date is mandatory")
    }
    else {
      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");
      const fd = new FormData();

      fd.append(`test`, testsList[i].test);

      fd.append(`date`, testsList[i].date);

      fd.append(`lab_test_details  `, testsList[i].lab_test_details);

      axios
        .put(`${APIURL}/api/v1/doctor/tests-details/${testsList[i].id}/`, fd, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            alert.success("Successfully Updated");
            setAddtest(true)
            setTestRefresh(!testRefresh)


          } else {
            alert.error("error");
          }
        })
        .catch((err) => {

        });
    }
  };

  const openEditTest = (index) => {
    handleUpdateTest(index);
  };
  const handleSubmitTest = () => {
    n = testsList.length;

    setTestErr("nil");
    const updatedTests = testsList.filter((row, i) => {
      return row.test !== "" && row.date !== "";
    });
    const testData = testsList.filter((row, i) => {
      return row.test !== "" || row.date !== "";
    });
    testData.map((item) => {
      if (item.test_name === "") {
        alert.error("*Test name in Test Schedules Table is mandatory!");
        setTestErr("error");
      } else if (item.id === "") {
        alert.error("*Select Test name from the dropdown!");
        setTestErr("error");
      } else if (item.date === "") {
        alert.error("*Date in Test Schedules Table is mandatory!");
        setTestErr("error");
      } else if (item.test !== "" || item.id !== "" || item.date !== "") {
        setUpdatedTestList(updatedTests);
        setSubmitTrigger(!submitTrigger);
        setPermission(true);
      }
    });
  };
  const renderUpdateTest = (cellInfo) => {
    if (cellInfo.index > testListCounter - 1 || testListCounter === 0) {
      return (
        <button
          className="btn btn-primary btn-col btn-square btn-smallest"
          onClick={handleSubmitTest}
        >
          Save
        </button>
      );
    } else if (testsList[cellInfo.index].isEdit) {
      return (
        <button
          className="btn btn-primary btn-col btn-square btn-smallest"
          onClick={() => {
            openEditTest(cellInfo.index);
          }}
        >
          Save
        </button>
      );
    } else {
      return (
        <i
          style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
          onClick={() => {
            let procedures = testsList;
            procedures.forEach((procedure) => {
              procedure.isEdit = false;
            });
            testsList[cellInfo.index].isEdit = true;
            setTestsList(procedures);
            setRender(!render);
          }}
          className="fas fa-edit"
          aria-hidden="true"
        ></i>
      );
    }
  };
  useEffect(() => {
    if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") {
      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");

      axios
        .get(
          `${APIURL}/api/v1/doctor/laboratory-tests/?patient_id=${props.id}&search=${search}`,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            setOptions(res.data.message);
          }
        });
    }
  }, [search]);
  const renderEditableTestDate = (cellInfo) => {

    if (
      cellInfo.index > testListCounter - 1 ||
      testsList[cellInfo.index].isEdit
    ) {
      const today = new Date();

      const offset = today.getTimezoneOffset();

      let dateUpdated = new Date(today.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      return (
        <div>
          <input
            type="date"
            data-date={dateVal}
            className="scan-date-col date-custom-format"
            min={DateExtracted}
            onChange={(e) => {
              e.preventDefault();
              let date = e.target.value;
              let y = []
              let x = testsList.filter(x => {
                return x.test_name === testsList[cellInfo.index].test_name
              })
              if (x.length > 0) {
                y = x.filter(x => {
                  return x.date === date
                })
              }
              if (y.length > 0) {
                const tests = testsList;
                tests[cellInfo.index].date = "";
                alert.error("already exist")
                setTestsList(tests);

                const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

                setDateVal(m);
                return
              }
              else {
                const tests = testsList;
                tests[cellInfo.index].date = date;

                setTestsList(tests);
                const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

                setDateVal(m);
              }
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-date-col"
            name="date"
            value={testsList[cellInfo.index].date}
            onBlur={(e) => {
              const tests = testsList;
              tests[cellInfo.index].date = e.target.innerHTML;

              setTestsList(tests);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: testsList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };
  const renderAddedBy = (cellInfo) => {
    const createdBy = testsList[cellInfo.index].created_by;
    const hospitalName = testsList[cellInfo.index].hospital_name;
    const value = `${createdBy}-${hospitalName}`;

    return (
      testsList[cellInfo.index].hospital_name && testsList[cellInfo.index].created_by &&
      <input
        style={{ backgroundColor: "#fafafa" }}
        className="form-control scan-name-col"
        readOnly
        value={value}
        cols="100"
        rows="2"
      />
    );
  };
  const deleteRow = (index, props) => {
    let newlist = [];
    if (index > testListCounter - 1) {
      newlist = testsList.filter((test, i) => {
        return i !== index;
      });

      setTestsList(newlist);
      setAddtest(true);

      if (newlist.length <= 0) {
        setTestsList([
          {
            id: "",
            test_name: "",
            date: "",
            lab_test_details: "",
            created_by: "",
          },
        ]);
      }
    } else {
      handleDeleteRowTest(index);
    }
  };
  const handleDeleteRowTest = (index) => {
    setDeleteTestConfirmMsg("success");

    setDeleteTestConfirmModalShow("true");
    setTestIndexToDelete(index);
  };
  const renderEditableTest = (cellInfo) => {
    if (cellInfo.index > testListCounter - 1) {
      return (
        <div ref={wrapperRef}>
          <input
            style={{ backgroundColor: "#fafafa" }}
            value={testsList[cellInfo.index][cellInfo.column.id]}
            className="form-control"
            name="test"
            onChange={(e) => {
              setAddtest(false);
              e.preventDefault();

              setCurrentIndex(cellInfo.index);
              setCurrentColumn(cellInfo.column.id);
              setDisplay1(true);

              setSearch(e.target.value);

              const tests = testsList;
              tests[cellInfo.index].test_name = e.target.value;

              setTestsList(tests);
              if (e.target.value === "") {
                setDisplay1(false);
              }
            }}
            autoComplete="off"
          />

          {currentIndex === cellInfo.index &&
            cellInfo.column.id === "test_name" &&
            display1 && (
              <div className="suggestions-box">
                {options.map((item, i) => {
                  return (
                    <div
                      className="scan-item"
                      key={item.id}
                      onClick={(e) => {
                        e.preventDefault();

                        setRender(!render);

                        const tests = testsList;
                        tests[cellInfo.index].test_name = item.test_name;
                        tests[cellInfo.index].id = item.id;
                        setTestsList(tests);
                        setDisplay1(false);
                      }}
                    >
                      <p className="search-item">{item.test_name}</p>
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control scan-name-col"
            name="test"
            value={testsList[cellInfo.index].test_name}
            onBlur={(e) => {
              const tests = testsList;
              tests[cellInfo.index].test = e.target.innerHTML;

              setTestsList(tests);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: testsList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };
  const AddRowTest = () => {
    setAddtest(false);
    const list = testsList;
    list.push({
      date: "",
      id: "",
      lab_test_details: "",
      test_name: "",
    });
    setTestsList(list);
    setDateVal('');
    setRender(true);
  };

  const renderTestDetails = (cellInfo) => {
    if (
      cellInfo.index > testListCounter - 1 ||
      testsList[cellInfo.index].isEdit
    ) {
      let data = "";
      if (
        testsList[cellInfo.index] &&
        testsList[cellInfo.index].lab_test_details
      ) {
        data = testsList[cellInfo.index].lab_test_details;
      }
      return (
        <div>
          <textarea
            className="text-area"
            placeholder="Add Test Details"
            cols="100"
            rows="2"
            defaultValue={data}
            onChange={(e) => {
              e.preventDefault();
              data = e.target.value;

              const tests = testsList;

              testsList[cellInfo.index].lab_test_details = data;

              setTestsList(tests);
            }}
          />
        </div>
      );
    } else {
      return (
        <textarea
          className="text-area"
          value={testsList[cellInfo.index].lab_test_details}
          cols="100"
          rows="2"
        />
      );
    }
  };
  const columns = [
    {
      Header: <i className="fa fa-trash"></i>,
      Cell: (props) => {
        return (
          <i
            style={{ marginLeft: "30%" }}
            className="fa fa-trash trash-btn"
            onClick={() => {
              deleteRow(props.index, props);
            }}
          ></i>
        );
      },
      width: 50,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Test Name",
      accessor: "test_name",
      Cell: renderEditableTest,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,

      sortable: false,
    },

    {
      Header: "Date (YYYY-MM-DD)",
      accessor: "date",
      Cell: renderEditableTestDate,
      sortable: false,
    },
    {
      Header: "Test Details",
      accessor: "lab_test_details",
      Cell: renderTestDetails,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Save/Edit",
      Cell: renderUpdateTest,
    },

    {
      Header: "Added By",
      Cell: renderAddedBy,
      width: 200,
    },

    {
      Header: "Add New",

      Cell: (props) => {
        return props.index === testsList.length - 1
          ? testsList[props.index].id
            ? addtest && (
              <button
                className="btn btn-primary btn-col"
                onClick={AddRowTest}
              >
                +
              </button>
            )
            : null
          : null;
      },
      sortable: false,
      filterable: false,
    },
  ];
  const DeleteTestConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div><div className="alert-border"><span className="alert-icon">!</span></div></div>
          <label style={{ margin: '20px auto', fontSize: '18px', color: '#4e4e4e' }}>Please confirm to delete scheduled test!</label>
          <div className="form-group" style={{ display: 'flex', margin: 'auto' }}>
            <button type="button" className='swirl-butns' style={{ margin: '10px 4px 0px 0px', backgroundColor: '#dc3545' }} onClick={props.onHide}> Cancel </button>
            <button type="submit" className='swirl-butns' style={{ margin: '10px 0px 0px 4px', backgroundColor: '#2b69a3' }} ref={delConfirmRef}
              onClick={() => {
                setDeleteTestConfirmModalShow(false);
                handleDeleteTest(props.index, props.newList);
                setTestIndexToDelete(null);
                setDateVal("")
              }}>
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const handleDeleteTest = (index, props) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const ts_id = testsList[index].id;

    axios
      .delete(`${APIURL}/api/v1/doctor/tests-details/${ts_id}`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
          alert.success(res.data.message)
          setTestRefresh(!testRefresh)
          setAddtest(true);
        } else {
          alert.error("error");
          setAddtest(true);
        }
      })
      .catch((err) => {
        alert.error("error");
        setAddtest(true);
      });
  };
  return (
    <div>
      <ReactTable
        columns={columns}
        data={testsList}
        pageSize={n1}

        showPagination={false}

        style={{ color: "black" }}
        className="observation-table"
      ></ReactTable>
      {deleteTestConfirmMsg === "success" ? (
        <DeleteTestConfirmPopup
          show={deleteTestConfirmModalShow}
          onHide={() => setDeleteTestConfirmModalShow(false)}
          index={testIndexToDelete}
          newList={testData1}
        />
      ) : (
        ""
      )}
    </div>
  )
} export default ManageTest